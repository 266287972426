:root {
    /* Set the deafult values */
    --primary-color: var(--selected-primary-color);
    --primary-bg-color: var(--selected-primary-bg-color);
    --secondary-bg-color: var(--selected-secondary-bg-color);
    --disabled-color: var(--selected-disabled-color);

    /* Set the 'light' theme */
    --selected-primary-color: #111;
    --selected-primary-bg-color: #f5f5f5;
    --selected-secondary-bg-color: #d7d7d7;
    --selected-disabled-color: #6c757d;
    --themecolor: #000000;
    --accentcolor:#000;
    --desktopCardHeight: 420px;
    --mobileCardHeight: 340px;
    /*shakers 480px  --- debanjana 550px*/
    --menulinkbgcolor:#d7d7d7;
    --topsectioncolor:#ffffff;


    --primaryColor:#ee2d7a;
    --secondaryColor:#ee2d7a;
    --tertiaryColor:#404040;
    /* --primaryColor:#fff;
    --secondaryColor:#fff; */
    /* --tertiaryColor:#fff;  */
        --color: 240, 240, 240;
        --alpha: 0.8;
}
#google_translate_element{
    position: fixed;
    z-index: 999999;
    bottom: 0;
    right: 1px;
    background: #fff;
    padding: 2px 4px;
    border:1px solid #b1acac;
}
body{
    background-color: #f7f7f7 !important;
}
.product_details_cont{
	overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}
.printme {
	display: block;
}
.aboutus-head{
	text-align:center;
}
.aboutus-body{
	margin:15px;
}
.aboutus-bodyer{
	text-align:center;
}
.contactus-body{
	margin-left:32px;
	height: 200px;
display: flex;
align-items: center;
justify-content: center;
}
.order_header{
	cursor:pointer;
	background:grey !important;
	color:white !important;
	text-align:center;
	
}
.ret-item{
    color: var(--primaryColor) !important;
    
}
.scroll_order{
	cursor:pointer;
	text-align:center;
}
.custom-modal-header .modal-title{
     font-size: 20px;
}
.order_card{
	border-radius:25px !important;
	width:90%;
}
.order_ack_button{
	width:100%; margin:0 auto;
}
.order_accordion{
	/* width:800px; margin:0 auto; */
}
.order_ack_button{
	color:white;
	width:40%;
	margin-right:150px;
	algin-content:center;
}
.not_cancel{
	color:red;
    font-size: 20px;
    padding: 14px 0;
    display: block;
}
.mega-arrow-svg{
    width:15px;
    margin-left: 7px;
}
.mega-arrow-svg line{
    stroke: #525252;
}
.megamenu-section-inner ul li a:hover .mega-arrow-svg line{
    stroke: var(--primaryColor);
}
.not_return{
	color:blue;
}
.brand-iconmaindiv-details{
    position:absolute;
    right:15px;
    top:15px;
}
.product-name-order
{
	margin-left:15px;
}
.order_inv_button{
	color:white;
	width:40%;
}

@media print {
	.no-printme  {
		display: none;
	}
	.printme  {
		display: block;
	}
}
.show {
    display: block;
}

.hide {
    display: none;
}

*{
    font-family: roboto;
}
.row{
    margin-left: 0 !important;
    margin-right: 0 !important;
}
p{
    margin-bottom: 0;
}

.color-brand-style{
    color: #ee2028 !important;
    font-size: 15px;
}

.commclass{
    font-size:17px;
}
.cart{
    color: #747474;
}
.cart:hover{
    color: #000;
}
.comm-hovclass{
    color: #747474; 
    position: relative;
}
.comm-hovclass:hover{
    color: #000;
    cursor: pointer;
}
.border-top1px{
    border-top:1px solid #464646;
}
.ul-namefooter li{
    list-style: none;
    padding: 5px 0;
}
input{
    outline:none !important;
}
button{
    outline:none !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: var(--primary-color);
    background-color: transparent;
}
.dropdown-item{
	font-size:13px !important;
}
.Toastify {
    z-index: 99;
}

.social-media{
	margin-top:	10px;
	margin-left:-25px;
}
.icon-facebook{
	margin-right:7px;
	color:black;
}
.icon-facebook-desktop{
	margin-right:7px;
	font-size:27px;
	color:black;
}
.icon-instagram{
	color:black;
}
.icon-instagram-desktop{
	margin-right:7px;
	font-size:27px;
	color:black;
}
.categorymenu-section{
	/* margin-left:33px; */
}
.pt-2-cus{
    position: relative;
    top:10px;
    margin-right: 13px;
    font-size: 22px;
}
/* .carousel .slide img{
    height: 400px !important;
} */

body{
    font-family: Roboto;
    /* background-color: var(--primary-bg-color); */
    color: var(--primary-color);
    background-color:#fff;
}.home-menuli{
    display: inline-block;
    vertical-align: top;
}
.bord-none{
    border:none;
}
.mains-pagelogo{
    width:200px;
}
.bgpinbuttonpromo{
    border: none !important;
    background-color: var(--primaryColor) !important;
    padding: 7px 10px 7px 10px !important;
    font-size: 13px !important;
    color: #fff !important;
    position: absolute;
    bottom: 0;
    left: 50% !important;
    font-weight: 600 !important;
    transition: 0.3s;
    transform: translate(-50%, -30%);
    width: 130px;
    border-radius: 50px !important;
}
.bgpinbuttonpromo:hover{
    color: #fff !important;
    border:2px dashed var(--tertiaryColor) !important;
    background-color:  var(--tertiaryColor) !important;
}
.bgpinbuttonpromo:focus{
    box-shadow: none !important;
}
.home-menu {
    text-align: right;
    background: var(--themecolor);
    padding: 8px 15px;
    position: fixed;
    top:0;
    width:100%;
    z-index: 9;
}
.image_render{
	width:100%;
	height:650px;
}

.cart{
    width: 100%;
    position: relative;
}
.cart-icon{
    /* margin-left: 28px; */
    position: relative;
    /* float: right; */
    font-size: 17px;
}
.amount-setdiv{
    position: absolute;
    width:100px;
    text-align: left;
}
.brand-controleachdiv{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.d-inline-block{
    vertical-align: top;
}
.wishtlist_header{
	text-align: center;
	font-size:20px;
}
.mob-srch-show{
    display: none !important;
}
.arrow-stylecontrol{
    font-size: 21px;
    padding:10px 0;
    cursor: pointer;
}
.brand-controleachdiv:last-child{
    border-right:none !important;
}
.user_dropdown{
	margin-left:35px;
    margin-top: 3px;
}
.address_accordion{
	width:100%;
}

.home-menuli{
    padding: 0 8px;
}
.home-menuli a{
    color: var(--menulinkbgcolor);
}
.pinmodal-wrapper{
    left: 0;
}
.menu-items{
    left: 0;
}
.hide-error {
    display: none;
}
.tagline{
    font-size: 14px;
    margin-top: 10px;
    color: var(--themecolor);
    font-weight: 500;
}
.theme-outline-button{
    color: var(--themecolor);
    border-color: var(--themecolor);
}
.theme-outline-button:hover{
    background-color: var(--themecolor);
    border-color: var(--themecolor);
    color:var(--primary-bg-color);
}
.theme-outline-button{
    box-shadow: none !important;
}
.mob-category{
    display: none;
}
.menu-items111 {
    position: fixed;
    top: 237px;
    width: 100%;
    left: 0;
    background: var(--topsectioncolor);
    box-shadow:0 0 10px #dadada;
}
.nav-justified .nav-item{
    flex-basis: auto;
}
.delete_cancel{
	margin-left:10px;
}
header{
    padding: 0px 0px;
    /* box-shadow: 0px 8px 18px rgba(0,0,0,0.033); */
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9;
    -webkit-animation: slideUp 300ms linear;
    animation: slideUp 300ms linear;
    background-color: var(--topsectioncolor);
    /* border-bottom:1px solid #dadada; */
    box-shadow: 0 0 5px #dadada;
}
.checkout_edit{
	margin-left:370px;
	cursor: pointer;
}
.checkout_edit_mobile{
	margin-left:185px;
	cursor:pointer;
}

.topsection-category{
    padding: 10px 0;
    background:var(--topsectioncolor);
    /* border-bottom: 2px solid #177913; */
    /* box-shadow: 1px -3px 7px #f1eded; */
}
.brand .logo{
    cursor: pointer;
    width: 180px !important;
}
 .show-pin-err {
    clear: both;
    display: block;
    font-size: 11px;
    width: 260px;
    margin-left: -80px;
    margin-top: 5px;
}
.hide-pin-err{
    display: none;
}
.pin-code img{
    width: 25px;;
}
.zipname{
    /* font-size: 14px; */
    color: var(--themecolor);
    cursor: pointer;
    font-weight: 700;
}
.zipname_err{
    /* font-size: 14px; */
    /* color: var(--themecolor); */
    color:#FF0000;
    cursor: pointer;

}

.Notification{
	color:#FF0000;
    cursor: pointer;
    font-weight: 400;
	font-size: small;
}
.notification{
    color:var(--topsectioncolor);
    background-color: #F00;
    padding:5px 0px;
    font-weight: 400;
	font-size: small;
    display: none;
}
.wallet{
    color:var(--topsectioncolor);
    background-color: #1d1e1f!important;
    padding:5px 0px;
    font-weight: 400;
	font-size: small;
    display: block;
}
.localization{
    float: right;
}
.notification-close{
    cursor: pointer;
    float: right;
    padding: 0px 10px;
}

.notification-indicator {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    /* height: 20px;
    width: 20px;
    border-radius: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25); */
  }
  .notification-indicator span {
    position: relative;
    font-size: 12px;
    top: 0px;
    left: 0px;
  }
  .extrassize_button button {
    width: 50px !important;
    min-width: 50px !important;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    font-size: 12px !important;
}
  .notification-indicator::after {
    opacity: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 8px solid rgba(255, 0, 0, 0.4);
    border-radius: 100%;
    -webkit-animation-name: ripple;
            animation-name: ripple;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
            animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: 0;
  }
  .notification-indicator::before {
    opacity: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 8px solid rgba(255, 0, 0, 0.4);
    border-radius: 100%;
    -webkit-animation-name: ripple;
            animation-name: ripple;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
            animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: 0;
  }

  @-webkit-keyframes ripple {
    from {
      opacity: 1;
      -webkit-transform: scale3d(0.15, 0.25, 1);
              transform: scale3d(0.15, 0.25, 1);
    }
    to {
      opacity: 0;
      -webkit-transform: scale3d(2.5, 2.5, 2.5);
              transform: scale3d(2.5, 2.5, 2.5);
    }
  }

  @keyframes ripple {
    from {
      opacity: 1;
      -webkit-transform: scale3d(0.25, 0.25, 1);
              transform: scale3d(0.25, 0.25, 1);
    }
    to {
      opacity: 0;
      -webkit-transform: scale3d(2.5, 2.5, 2.5);
              transform: scale3d(2.5, 2.5, 2.5);
    }
  }

.cursor {
    cursor: pointer;
}

.search-form {
    display: flex;
    display: -webkit-flex;
    margin-bottom: 0;
}
.search-form .search-keyword {
    height: 40px;
    border: none;
    padding: 0 15px 0 40px;
    border-radius: 35px;
    width: 100% !important;
    font-size: 15px;
    background-color: #f5f5f5;
    font-style: italic;
    width:270px !important;

}
.prod-div {
    display: inline-block;
    width: 260px;
}
.mar-0-auto{
    margin: 0 auto;
}
.mobile-seach-div{
    display: flex;
}
.search-form .search-button {
    border-radius: 0 2px 2px 0;
    width: 40px;
    background: #ffffff;
    border: none;
    border-radius: 3px;
    /* border-bottom-left-radius: 0;
    border-top-left-radius: 0; */
    font-size: 18px;
    /* border: 1px solid var(--themecolor);
    color: var(--themecolor) !important; */
    border-left: none;
    background-color: #f3f3f5;
    color:#929292 !important;
}
.mobile-search{
    display: none;
}
.map-icon{
    font-size: 20px;
    color: var(--selected-primary-color) !important;
}
.cart-info {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
}
.icon-image {
    width: 20px;
}
.branding-section {
    width: 100%;
    background: var(--primary-bg-color);
    padding: 10px 0;
    box-shadow: 0 0 2px var(--primary-color);

}
.full-Pagesection{
    padding: 125px 0 50px 0;
}
/* .wishlist-cart{
	margin-top:-8px;
}
.wishlist-move{
	margin-top:1px;
	cursor:pointer;
} */
.branding-div{
    display: inline-block;
    vertical-align: top;
}
.branding-div-first img {
    width: 65px;
}
.brands-name {
    font-size: 16px;
    text-transform: uppercase;
    color: #333;
    margin: 20px 0 20px 0;
    font-weight: 600;
}
.branding-div-third img {
    width: 65px;
}
.branding-div-haringhata img {
    width: 95px;
}
.branding-div-gogras img {
    width: 120px;
}
.addressbrands-second {
    font-size: 14px !important;
    color: #333;
}
.branding-div-sec4 p {
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
}
footer{
    /* position:fixed; */
    width:100%;
    left: 0;
    bottom: 0;
    background-color: #1b1b1b !important;
    z-index: 9;
    padding-bottom: 15px !important;
}
.bord-right1px{
    border-right: 1px solid #dadada;
}
.bord-bot-5{
    border-bottom: 5px solid #efefef;
}
.categories-section .nav-link {
    border-radius: 0 !important;
    background: none;
    margin: 0;
    font-size: 16px;
    padding: 6px 40px 27px 10px;
    color: #525252 !important;
    border-radius: 0;
    text-align: left;
    text-transform: capitalize;
    transition: 0.3s;
    line-height: 20px;
}
.categories-section .nav-link:hover{
    color: var(--primaryColor) !important;
}
.products-wrapper{
    margin-top: 20px;
}
.single-amt {
    color: #757575;
    font-size: 14px;
    /* font-weight: 600; */
    }
    .lh-16{
        line-height: 16px ;
    }
.product-box {
    /* background: var(--selected-primary-bg-color); */
    /* padding: 7px 7px 7px 7px; */
    padding: 0;
    position: relative;
    min-height: var(--desktopCardHeight);
    margin-bottom: 20px;
    /* box-shadow: 0 0 1px var(--selected-primary-color); */
    /* border:1px solid #dadada; */
    /* box-shadow: 0 0 5px #dadada; */
    border:1px solid #eaeaea;
    background-color: #f5f5f5;
    border-radius: 0 !important;
}
/* .product-box:hover{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.53);
} */
.product-image {
    position: relative;
    text-align: center;
    border:none;
}
.ps-static .badge{
    position: static !important;
    margin-left: 10px;
    float: right;
}
.product-box:hover {
    box-shadow: 0 0 5px #dadada;
}
.product-img{
    width: 100%;
    cursor: pointer;
    border:none;
    height:342px;
    transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
}
/* .product-img:hover{
    transform: scale(1.07);
} */
.l-0{
    left:0;
}
.b-0{
    bottom: 0
}
.b-35{
    bottom:35px;
}
.b-40{
    bottom:40px;
}
.brand-iconmaindiv {
    width: 35px;
    top: 6px;
    left: 6px;
    position: absolute;
    z-index: 0;
    background: #ffffff;
    height: 35px;
    border-bottom-right-radius: 2px;
}
.brand-iconmaindiv .brand-img{
    width: 100%;
}
.product-action {
    padding: 0px;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
}
.addtocart-btn {
    height: 34px;
    border-radius: 0px;
    text-align: center;
    color: #fff;
    background: #ee2028;
    border: 0;
    font-weight: 400;
    padding: 0 16px;
    font-family: inherit;
    font-size: 15px;
    cursor: pointer;
    width: auto;
}
.primary-button {
    height: 35px;
    border-radius: 0px;
    text-align: center;
    color: #fff;
    background-color: var(--primaryColor) !important;
    font-weight: 500 !important;
    padding: 2px 13px !important;
    font-family: inherit;
    font-size: 15px !important;
    cursor: pointer;
    border:none !important;
    transition: 0.4s;
    box-shadow: none !important;
    border-radius: 2px;
}
.zoom_button{
    color: white;
    border: none;
    margin: 3px;
    min-width: 30px;
    padding: 5px 10px;
    font-size: 12px;
}
.primary-button-nocolor {
    height: 37px;
    border-radius: 4px !important;
    text-align: center;
    color: #fff;
    font-weight: 500 !important;
    padding: 2px 13px !important;
    font-family: inherit;
    font-size: 15px !important;
    cursor: pointer;
    border:none !important;
    transition: 0.4s;
    box-shadow: none !important;
    border-radius: 2px;
}
.primary-button:hover{
    background-color: var(--tertiaryColor) !important;
}
.only-otp-div{
    padding: 22px 35px !important;
}
.secondary-button{
    color: var(--tertiaryColor);
    border:none;
    background-color: transparent;
    font-weight: 400;
    font-family: inherit;
    font-size: 15px;
    height: 35px;
    padding: 5px 10px;
    transition: 0.4s;
    border: 1px solid var(--tertiaryColor) !important;
    color: var(--tertiaryColor) !important;
    border-radius: 2px;
    padding: 0 10px;
    height: 35px;
}
.mobile-style-nofixed-button{
    display: none;
}
.secondary-round-button{
    background-color: transparent;
    border: 1px solid var(--primaryColor);
    border-radius: 2px;
    height: 35px;
    color: var(--primaryColor);
    font-weight: 500 !important;
    padding: 2px 13px !important;
    font-family: inherit;
    font-size: 15px !important;
    transition: 0.3s;
}
.secondary-round-button:hover{
    background-color: var(--primaryColor);
    color: #fff;
}
.second-primary-button{
    background-color: var(--secondaryColor) !important;
}
.second-primary-button:hover{
    color: #fff;
    background-color: var(--tertiaryColor);
    border:1px solid var(--tertiaryColor) !important;
}
/* .secondary-button:hover{
    background-color: var(--tertiaryColor);
    color: #fff;
} */
.secondary-button_mobile{
    color: black;
    border:none;
    background-color: transparent;
    font-weight: 400;
    font-family: inherit;
    font-size: 15px;
    height: 35px;
    padding: 0;
    border: 1px solid var(--tertiaryColor) !important;
    color: var(--tertiaryColor) !important;
    border-radius: 2px;
    padding: 0 10px;
}
.size-button{
	margin-left:-25px;
}
.min-same-width{
    min-width: 107px;
}
.min-same-width_mobile{
    min-width: 112px;
}
.mobile-search-pannel{
    display: none;
}
.variant-select-btn-control button {
    margin-right: 10px;
    border: 1px solid #000000;
    min-width: 100px;
    margin-bottom: 10px;
    background-color: transparent;
    font-size: 14px;
    padding: 6px 2px;
}
.active-variant-btn {
    color: #939393;
    border: 1px solid #d1d1d1 !important;
    background-color: #dbdbdb !important;
}
.percentage_off{
    color: var(--primaryColor);
}
.product-action button{
    height: 34px;
    border-radius: 0px;
    text-align: center;
    color: #fff;
    background: var(--themecolor);
    border: 0;
    font-weight: 400;
    padding: 0 16px;
    font-family: inherit;
    font-size: 14px;
    cursor: pointer;
    width: 130px;
    border-radius: 36px;
    margin-bottom: 10px;
}
.elips-twoline{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 34px;
}
.elips-threeline{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 40px;
}
.product-name {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
    color: var(--selected-primary-color);
}
.rating-text{
    color: var(--primaryColor) !important;
}
.order-bg{
    background-color: #fbfbfb !important;
}
.order-bg:hover{
    box-shadow: 0 0 5px #dadada !important;
}
.product-des{
    font-size: 11px;
    line-height:13px;
    color: #868686;
}
.product-name-home {
    overflow: hidden;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 12px;
}
.jc-flc{
    justify-content: center;
    flex-direction: column;
}
.product-box .stepperc-input{
    width:100%;
}
.product-box .product-name{
    font-size: 13px;
    font-weight: 600;
    line-height:16px;
}
.bg-light-modal{
    background-color: #f4f4f4;
}
.hc-modal{
    height:calc(100vh - 145px);
    overflow-x: hidden;
    overflow-y: auto;
}
.product-name-local {
    height: 42px;
    overflow: hidden;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 15px;
    display: none;
}
.product-price-checkout {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
	margin-top:-37px;
    margin-bottom: 15px;
    text-align: center;
}
.coming-soon{
	margin-left:560px;
}
.payment_ins_info{
	margin-top:66px;
}
.information_term{
	height:18px;
	margin-top:-3px;
}
.product-price {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 15px;
    text-align: center;
}

.product-name-price{
    position: absolute;
    width: 100%;
    bottom: 45px;
    margin-bottom: 0;
    left: 0;
}
.stepperc-input{
    width:auto;
    text-align: center;
    display: inline-block !important;
    position: relative;
    /* top:-15px; */
}
.decrement{
    display: inline-block;
    vertical-align: top;
    height: 28px;
    padding: 1px 9px;
    width: 26px;
    cursor: pointer;
    height: 30px;
    color: #3d3f3c!important;
    background-color: transparent!important;
    color: #b4b4b4!important;
    border: 1px solid #ebebeb;
    border-right: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.decrement img {
    width: 12px;
    position: relative;
    top: -2px;
}
.quantity{
    width: 60px;
    margin: 0 auto;
    border-radius: 0;
    text-align: center;
    border: 1px solid #ebebeb;
    height: 30px;
    color: #525252;
    font-weight: 600;
    background: #f6f6f6;
    font-size: 14px;
}
.increment{
    display: inline-block;
    vertical-align: top;
    height: 28px;
    padding: 2px 9px;
    width: 26px;
    cursor: pointer;
    height: 30px;
    color: #3d3f3c!important;
    background-color: transparent!important;
    color: #b4b4b4!important;
    border: 1px solid #ebebeb;
    border-left: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.lh-15{
    line-height:15px;
}

.cart-icon img{
    height: 20px;
}
.cart-count {
    display: block;
    position: absolute;
    background-color: var(--primaryColor);
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    width: 20px;
    border-radius: 50%;
    top: -4px;
    left: 15px;
    font-weight: 600;

}
.mob-category .navbar-toggler{
    border-color:transparent !important;
}
.tada{
    /* width:18px; */
    color: #525252;
}
.itemlist{
    margin-top: 10px;
}
.cart-brand-iconmaindiv{
    display: block;
    position: relative;
}
.product-image-cart{
    width:100% !important;
}
.onr-rigt{
    text-align: left;
}
.cart-item {
    margin-bottom: 2px;
    background: #fff;
    padding: 5px 0;
}
.chk-out-button-mobal button{
    width: 100%;
    background-color: rgb(229 50 50 / 60%);
    border: none;
    box-shadow: none;
    color: #fff;
    padding: 7px 10px;
    text-align: left;
    border-radius: 2px;
    border: none !important;
    font-size: 14px;
}

.checkout-disable{
    cursor: no-drop;
    opacity: 0.7;
}
.google-colorstyle{
    color: #3c4043;
    font-size: 14px;
}
.checkout-count .checkoutstepperc-input {
    text-align: left;
    top:auto;
}
.cart-items{
    background-color: #fff;
    padding-top:0px;
    padding-bottom:0px;
    border:1px solid #e6e3e3;
    margin-bottom: 70px;
}
.inblkclass-top img{
    width:25px;
}
.product-remove-top {
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 20px;
    color: red!important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0 6px;
    text-decoration: none!important;
}
.total-amnt-lbl {
    font-size: 24px;
    font-weight: 600;
}
.total-cart-amount{
    position: relative;
    background-color: #fff;
    box-shadow: 2px 1px 8px 1px #d8d8d8;
    padding: 0 15px;
    text-align: right;
}
.empty-cart{
    text-align: center;
}
.inblkclass-top {
    position: absolute;
    top: 7px;
    left: 7px;
}
.pos-fixedclass{
	margin-top:70px;
    position:fixed;
    width:100%;
    bottom: 35px;
     z-index: 9;
     box-shadow: 0 0 5px #dadada;
background: #fff;
}
.checkout_container{
	margin-top:119px;
}
.checkout_mobileheader{
	margin-left:-16px;
}
.checkout_mobilebrand{
	margin-left:-377px;
}
.checkout_mobileback{
	margin-top:-22px;
}
.checkout_mobilecon{
	margin-top:84px;
}
.chk-out-button{
    background: #fff;
    box-shadow: 0 0 5px #afaeae;
    padding: 10px 15px;
    text-align: right;
}
.chk-out-button button {
    background: var(--accentcolor);
    min-width: 200px;
    padding:5px;
    border:none;
    box-shadow: none;
    color: #fff;
    outline: none !important;
}
.border-none{
    border-bottom: none !important;
}
.product-links-anchor{
    min-width: 150px;
    display: inline-block;
    padding: 5px 5px;
    text-align: center;
    border: 1px solid #3d3f3c;
    background-color: #3d3f3c !important;
    text-decoration: none !important;
    font-size: 14px;
    color:#fff !important;
}
.cost_shift{
	margin-left:-31px;
	/* margin-top:40px; */
}
.cost_shift_mobile{
	margin-left:-4px;
	margin-top:23px;
}
.quantity_shift{
	margin-top:5px;
}
.chkout-main-imgpanel{
    position: relative;
}
.cart-brand-img{
    position: absolute;
    width: 15px;
    z-index: 001;
    box-shadow: 1px 1px 5px #b5b3b3;
    background: #fff;
    left: 1px;
    top: 1px;
}
.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

.br-0{
    border-radius: 0;
}
.product-description-mainname{
    font-size: 20px;
    font-weight: 600;
}
.quick-view-image{
    position: relative;
    border:1px solid #dadada;
}
.thumbs-wrapper {
    margin: 20px 0 !important;
}
.thumbs{
    padding-left: 0 !important;
}
.sl-localname{
    font-size: 16px;;
}
/* .primary-button{
    background: var(--accentcolor);
    min-width: 100px;
    padding: 5px 10px;
    border: none;
    box-shadow: none;
    color: #fff;
    outline: none !important;
} */
.product-price-product-details {
    font-size: 16px;
    color: #212529;
}
.clear_discount{
	margin-top: 8px;
    margin-right: -1px;
    cursor: pointer;
    color: red !important;
    font-size: 14px;
    font-style: italic;
}
.bot-autofd-div{
    bottom: auto;
    top:0;
}
.related-section{
    background-color: #fff;
}
.bx-shadow-withbord{
    box-shadow: none;
    border-bottom: 1px solid #efefef;
}
.control-dots .dot {
    box-shadow: 0 0 3px var(--accentcolor) !important;
    background: var(--accentcolor) !important;
    width: 10px !important;
    height: 10px !important;
}
.bgpinbutton{
    background: var(--accentcolor) !important;
    border-color: var(--accentcolor) !important;
}
.quick-view-details .stepperc-input{
    text-align: left !important;
    top:0 !important;
}
.final-ord-list{
    padding: 12px 10px;
    border-bottom: 1px solid rgb(235 235 235 / 80%);
}
.final-ord-list:nth-child(odd){
    background-color: rgb(235 235 235 / 20%);
}
.fs-13-f-n{
    font-size: 12px;
    font-weight: normal;
}
.fs-14{
    font-size: 14px;
}
.font-12{
    font-size: 12px;
}
.bgf2f2f2{
    background-color: #f2f2f2;
}
.bord-bot-theme{
    border-bottom: 2px solid var(--themecolor);
}
.mob-categorybutton{
    height: 36px;
    border-radius: 0px;
    text-align: center;
    color: #fff;
    background: var(--themecolor);
    border: 0;
    font-weight: 400;
    padding: 0 12px;
    font-size: inherit;
    cursor: pointer;
    border-radius: 20px;
}
.top-50-martop{
    margin-top: 0px;
}
.variant-right {
    padding: 5px 0px;
    border: 1px solid #dadada;
    border-left: 5px solid var(--themecolor);
    margin-bottom: 8px;
}
.variant-right-active{
    border-left: 5px solid var(--themecolor);
}
.close-button {
    padding: 2px 10px;
    border:none
}
.close-button .close{
    color:red !important;
    opacity: 1 !important;
}
.centered-div{
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
}
.centered-div .stepperc-input{
    top:0
}
.variant-item-promo-price {
    color: #868686;
    font-size: 15px;
}
.variant-select-btn-control button{
    margin-right: 10px;
    border:1px solid #868686;
}
.grand-total{
    background: #f2f2f2;
    padding: 6px 5px;
    color: var(--tertiaryColor) !important;
}
.themebg{
    background: var(--themecolor) !important;
    border-color: var(--themecolor) !important;
}
.f-size{
    font-size: 15px;
}
.totaltd {
    font-size: 13px;
    font-weight: 600;
}
.cl-btn-white .close{
    color:#fff;
    opacity: 1 !important;
}
.f-size22{
    font-size: 22px;
}
.number-before-check{
    width: 22px;
    height: 22px;
    margin-bottom: 0;
    border-radius: 50%;
    border: 2px solid var(--themecolor);
    font-size: 12px;
    padding: 0 6px;
    font-weight: 600;
    color: var(--themecolor);
    vertical-align: top;
}
/* Disable number type input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.hide-error{
    color:red;
    font-size: 14px;
}
.show-error{
    color:red;
    font-size: 14px;
}
.cart-modals .modal-dialog{
    margin:0;
    float: right;
}
.cart-modals .modal-content{
    border-radius: 0;
    border:none;
}
.cart-modals .modal-header{
    background-color: #333;
    padding: 10px 15px;
    border-radius: 0;
}
.cart-modals .modal-title{
    color:#fff;
    font-size: 17px;
}
.cart-modals .close {
    opacity: 1;
    color: #fff;
    font-weight: 100;
}
button[disabled]{
    color:#fff;
}
.z-index1{
    z-index: 1;
}

.percent-offer{
    background: rgba(255,0,0,.7);
    background-color:var(--primaryColor);
    display: inline-block;
    position: absolute;
    top: 16px;
    left: 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    z-index: 1;
    padding: 2px 4px;
    line-height: 16px;
  }
  .percent-offer:before{
    content: "";
    position: absolute;
    /* border-color: rgba(255,0,0,.5) transparent rgba(255,0,0,.5) rgba(255,0,0,.8); */
    border-color:var(--primaryColor,0.5) transparent var(--primaryColor,0.5) var(--primaryColor,0.8);
    border-style: solid;
    border-width: 10px;
    left: 100%;
    width: 0;
    height: 0;
    top: 0;
    opacity: 0.7;
  }
  .percent-offer:after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 14px solid var(--primaryColor);
    border-bottom: 10px solid transparent;
    opacity: 0.5;
  }
  .label-offer {
    right: 0;
    top: 25px;
    font-size: 12px !important;
    font-weight: normal;
    border-radius: 0;
    background: red;
    min-width: 90px;
}
.label-wish {
    right: 4px;
top: -43px;
font-size: 10px !important;
font-weight: normal;
border-radius: 0;
width: 35px;
height: 35px;
background: #fff;
box-shadow: 0 0 5px #d2cdcd;
border-radius: 50%;
}

.wishlisted{
	cursor:pointer;
	color:#ff3e6b;
    padding:7px 0;
}
.not-wishlisted{
	cursor:pointer;
	color:black;
    opacity: 0.5;
    padding:7px 0;
}
.label-wish-product {
    font-size: 10px !important;
    font-weight: normal;
    border-radius: 0;
    width: 36px;
    height: 36px;
    display: block;
    background: #fff;
    box-shadow: 0 0 5px #bbb7b7;
    border-radius: 50%;
    padding: 2px 0px;
}
.brand-iconmaindiv-details .label-wish{
    display: block;
    padding: 2px 0;
}
.bg-red{
    background: red;;
}
.font-size14px{
    font-size:14px;
}
.font12px{
    font-size: 12px;
}
.carousel-status{
    display: none;
}
.arrow-style{
    font-size:25px;
    color: #000 !important;
    cursor: pointer;
}
.sub_sub_category .dropdown-item {
        padding:9px 15px 9px 15px !important;
        background: none !important;
        border-bottom: 1px solid #f1efef;
}
.dropright .dropdown-toggle::after{
    border-top: 0.4em solid transparent;
    border-bottom: 0.4em solid transparent;
    border-left: 0.4em solid;
    position: absolute;
    right: 12px;
    top: 17px;
   
}
.subcategory_dropdown .dropdown-toggle::after{
    font-family: FontAwesome !important;
    border-top:none !important;
    border-left:none !important;
    border-right: none !important;
    content: "\f0d7";
    position: relative;
    top: 4px;
    left: 10px;
    margin-left: 0;
}
/*.sub_sub_category .dropdown-item:hover {
        background-color: #E8E6E5 !important;
}
.subcategory_dropdown:hover {
	background-color: #E8E6E5 !important;
}
.navitem:hover{
	 background-color: #E8E6E5 !important;
}*/
.sub_sub_category .dropdown-toggle{
    background:none !important;
}
.sub_category .dropdown-menu{
    padding: 0 !important;
    background: #fff !important;
    width:auto;
    border-radius: 00;
    padding: 0px 0 !important;
    top: 12px !important;
    border:1px solid #e4e2e2;
}
.subcategory_dropdown{
    text-align: left !important;
}
.sub_sub_category .dropdown-menu{
    min-width:250px !important;
}
.sub_sub_category .dropdown-toggle{
    text-align: left !important;
    padding: 9px 15px 9px 15px !important;
    margin:0 !important;
    font-size: 13px;
    display: inline-block !important;
    border-bottom: 1px solid #f1efef;
    min-width: 250px;
}
.dropdown-item.active, .dropdown-item:active {
    color: #000;
    text-decoration: none;
    /* background-color: #d7d7d7; */
}
.product_loading{
    /* width:250px;
    border:2px solid darkgray; */
    background: linear-gradient(-45deg, #aaa, #eee);
      background-size: 250% 250%;
    animation: gradient 0.5s ease infinite;

  }
  .transparent-bg {
    display: inline-block !important;
  }
  .transparent-bg .list-group-item {
    background: transparent;
    display: inline-block;
  }
 
  .prod_name{
      padding-left: 10px;
      font-size: 13px;
      font-weight: 600;
    line-height: 16px;
  }
  .min_height{
      margin-top: 24px;
      margin-bottom: 35px;
  }
  .bttn{
    height: 34px;
    border-radius: 34px;
    text-align: center;
    color: #808080;
    background: #808080;
    border: 0;
    font-weight: 400;
    font-family: inherit;
    font-size: 14px;
    cursor: pointer;
    width: 75%;
    margin-left: 171px;
    margin-bottom: 12px;
  }
.bu{
   color:#808080;
   border-color:  #808080;
   box-shadow: none !important;
}
.bu:hover{
    background-color: #808080;
    border-color: #808080;
    color:#fff;
}
.footer-right-section a{
    font-size: 13px;
    padding:0 15px ;
}
.carousel .thumb.selected, .carousel .thumb:hover{
    border:1px solid #000 !important;
}
.dropdown-item.active, .dropdown-item:active{
    background-color: transparent !important;
    padding:9px 15px 9px 15px !important;
}
.dropdown-item{
    background-color: transparent !important;
    color: #000!important;
    padding:9px 15px 9px 15px !important;
}
.dropdown-item:hover{
    color: #8ac640 !important;
}
.placeorder-button{
    background-color: #ff3e6b;
    padding: 7px 25px;
    border:none;
    border-radius: 3px;
    outline: none;
    color:#fff
}
.sl-prodcode {
    font-size: 18px;
    margin-bottom: 5px !important;
    color: #5b5b5b;
    font-weight: 500;
}

    @font-face {
        font-family: 'Futura, sans-serif';
        src: url(/src/fonts/futur.ttf), url(/src/fonts/Futura-CondensedLight.otf), url(/src/fonts/Futura\ Bold\ font.ttf), url(/src/fonts/Futura\ Bold\ Italic\ font.ttf), url(/src/fonts/Futura\ Book\ font.ttf),url(/src/fonts/Futura\ Book\ Italic\ font.ttf), url(/src/fonts/Futura\ Extra\ Black\ font.ttf), url(/src/fonts/Futura\ Heavy\ font.ttf), url(/src/fonts/Futura\ Heavy\ Italic\ font.ttf), url(/src/fonts/futura\ light\ bt.ttf), url(/src/fonts/Futura\ Light\ font.ttf), url(/src/fonts/Futura\ Light\ Italic\ font.ttf), url(/src/fonts/futura\ light\ bt.ttf), url(/src/fonts/Futura\ Light\ font.ttf) format('ttf');
        
      }
.header-top {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #E2E2E2;
    padding: 8px 0px 8px 0px;
}
.categories-section .nav-item{
    flex:none;
    flex-basis: inherit !important;
    flex-grow: inherit !important;
    font-family: 'Futura, sans-serif';
}
.search-buttontext-light {
    border-radius: 0 2px 2px 0;
    width: 60px;
    background: #fff;
    border-top-left-radius: 5px;
    font-size: 18px;
    /* border: none; */
    background-color: #fbfbff;
    color: #000000!important;
    border: 1px solid #f2f2f2 !important;
    border-right: none !important;
    border-bottom-left-radius: 5px;
}
.categories-section .nav{
    justify-content: left;
}
.Banner-crousal .carousel .slide img {
     width: auto;
    vertical-align: top;
    border: 0;
    height: 400px !important;
    width: 100%;
    object-fit: cover;
}
.Banner-crousal .carousel .slide{
    background-color: transparent !important;
}
.topsection-header{
     padding: 5px 0px 0px 0px;
    background: transparent;
    /* padding-bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 85px; */
}

.prod-img{
    width: 100%;
    height: 200px !important;
    transition: 0.3s;
    padding: 15px 10px;
    margin-bottom: 0px;
    object-fit: contain !important;
}

.product_name.text-center {
    text-transform: capitalize;
    font-size: 14px;
    color:#525252;
}
.product_name.text-center a {
    text-transform: capitalize;
    font-size: 15px;
    color: #707070;
    text-decoration: none !important;
    /* font-weight: 600; */
    margin-bottom: 5px;
}
.vendor.text-center {
    color: #a1a1a1;
    font-size: 12px;
    font-weight: 400;
}
.Price-renge {
    font-size: 16px;
    color: #525252;
}
.prod-img:hover{
    /* transform: scale(1.08); */
    cursor: pointer;
}
.footer-ulmain li {
    list-style: none;
}
.footer-ulmain li a{
    color:#fff !important;
    line-height: 33px;
    font-size: 16px;
    text-decoration: none !important;
}
.social-ul li {
    display: inline-block;
    padding: 0 10px;
}
.footer-p-p{
    color:#fff !important;
    font-size: 16px;
    padding: 0;
    font-weight: 100;
}
.footer-catd-ul{
    padding: 0;
    width: 100%;
    text-align: center;
}
.footer-catd-ul li{
    list-style: none;
    color: #fff;
    padding: 0 10px;
    display: inline-block;
    font-size: 30px;
}
.footer-lst-p{
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 0;
    color: #fff;
    letter-spacing: 1px;
}
.price-new{
    font-weight: 600;
}
.price-old{
    font-weight: 600;
    color: var(--primaryColor);
    text-decoration: line-through;
    font-size: 15px;
    padding-left: 5px;
}
.srch-div{
    padding: 18px 0 10px 0;
}
.rest-srchdiv {
    padding: 17px 0px 0px 0;
}
.comm-hovclass:hover .hovin-hov{
    display: block;
}
.hovin-hov{
    position: absolute;
    width: 220px;
    min-height: 60px;
    background-color: #ffffff;
    z-index: 99;
    top: 35px;
    border-radius: 0px;
    left: -25px;
    font-size: 23px;
    padding: 13px 20px;
    border: 1px solid #e9e9e9;
    box-shadow: 0 0 3px #dadada;
    display: none;
}
label.log-user {
    cursor: pointer;
    font-size: 13px;
}
.head-shadow {
     border-bottom: 1px solid #e2e2e2;
    box-shadow: -1px 3px 8px -4px #c3c1c1;
}
.full-page-body-withmegamenu .head-shadow{
    border-bottom: none;
    box-shadow: none;
}
.megamenu {
    z-index: 9;
    position: fixed;
    top: 183px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: auto;
    height: 100%;
    display: none;
    transition: 0.5s;
}
.megamenuinner {
    width:1150px;
    background: #fff;
    height: 350px;
    margin: 0 auto;
    box-shadow: inset 0 25px 10px -25px #ccc;
    margin-top: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.5s;
}
.mega-arrow{
    font-size: 13px;
    margin-left: 5px;
}
.label-1-child{
    display: inline-block;
    vertical-align: top;
    width: 320px;
    position: relative;
    height: 100%;
}
.label-1-child-ul li{
    padding: 10px 15px;
    list-style: none;
    /* background-color: #f7f7f7;
    border-bottom: 1px solid #e5e5e5; */
    cursor: pointer;
}
.label-1-child-ul li a {
    color: #000 !important;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
   
}
.label-1-subchild{
    position: absolute;
    width: 680px;
    left: 320px;
    top:10px;
    display: none;
}
.label-1-subchild li{
    background-color: transparent;
    border-bottom: none;
    width:33%;
    text-align: center;
    display: inline-block;
}

.subcat-img{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid #dadada;
    margin: 0 auto;
}
.subcat-img img{
    width:100%;
}
.step1:hover{
    border-left: 5px solid #8ac640;
}
.step1:hover .label-1-subchild{
    display: block;
}
.step1:hover .step1 a{
    color: #fff !important;
}
.old-typemenu{
    display: none;
}
.subcategory_dropdown:hover .old-typemenu{
    display: block;
}
.label-1-subchild li a{
    margin-top: 10px;
    display: block;
    color:#000;
}
.full-header {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 99;
    top:0;
}
.full-page-body {
    padding-top: 120px;
}
.full-page-body-withmegamenu{
    padding-top: 0;
}
.faq-header-label {
    margin: 0 0 10px 0; 
    font-size: 23px;
    font-weight: 600;
    color: #525252;
}
.faq-header-p{
    margin: 15px 0 20px 0;
    font-size: 17px;
}
.MegaDropdown-ContentInner {
    width: 100%;
    float: left;
    text-align: left;
}
.MegaDropdown-ContentHeading {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    text-decoration: none;
    color: rgba(3, 2, 26, 0.5) !important;
    padding: 12px 10px;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    transition: 0.3s;
}
.megaDropdown{
    display: none;
}
/* .MegaDropdown-ContentInner:hover .megaDropdown{
    display: block;
} */
.MegaDropdown-ContentHeading:hover  {
    background-color: var(--primaryColor) !important;
    color: #fff !important;
  
}
.ASSSS .MegaDropdown-ContentHeading  {
    background-color: var(--primaryColor) !important;
    color: #fff !important;
}
.ASSSS .MegaDropdown-ContentHeading  a{
    color: #fff !important;
}
.MegaDropdown-ContentHeading:hover a{
    color: #fff !important;
}
.ASSSS .MegaDropdown-ContentHeading:hover a{
    color: #fff !important;
}
.MegaDropdown-ContentHeading a {
    color: #525252 !important;
    text-decoration: none !important;
}
/* .MegaDropdown-ContentInner:hover .roticon{
    transform: rotate(90deg);
} */
.bord-bots{
    border-bottom: 1px solid #efefef;
}
.subsubmenumaindiv:hover{
    background-color: var(--secondaryColor);
}
.subsubmenumaindiv:hover .sub_submenu{
    color: #fff !important;
}
/* .MegaDropdown-ContentHeading a:hover{
    color:#f25c05 !important;
} */
.sub_submenu:hover{
    color:var(--primaryColor) !important;
}
.megaDropdown > ul {
    padding: 0;
    list-style: none;
}
.megaDropdown ul li {
    position: relative;
}
 .megaDropdown ul li a {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin: 2% auto 7px auto;
    display: block;
    color: rgba(3, 2, 26, 0.5);
    text-transform: capitalize;
    text-decoration: none;
}
.megaDropdown ul li a:hover{
    color:var(--primaryColor);
}
.bgGrey {
    background-color: #f7f7f7;
}
/* Shared */
.loginBtn {
    box-sizing: border-box;
    position: relative;
    /* width: 13em;  - apply for fixed size */
    margin: 0.2em 1em 0.2em 0.2em;
    padding: 0 10px 0 45px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #FFF;
    min-width: 210px;
  }
  .loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
  }
  .loginBtn:focus {
    outline: none;
  }
  .loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
  }
  
  
  /* Facebook */
  .loginBtn--facebook {
    background-color: #4C69BA;
    background-image: linear-gradient(#4C69BA, #3B55A0);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354C8C;
  }
  .loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
  }
  .loginBtn--facebook:hover,
  .loginBtn--facebook:focus {
    background-color: #5B7BD5;
    background-image: linear-gradient(#5B7BD5, #4864B1);
  }
  
  
  /* Google */
  .loginBtn--google {
    /*font-family: "Roboto", Roboto, arial, sans-serif;*/
    background: #DD4B39;
  }
  .loginBtn--google:before {
    border-right: #BB3F30 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
  }
  .loginBtn--google:hover,
  .loginBtn--google:focus {
    background: #E74B37;
  }
  .log-footer {
    background-color: #f5f5f5;
    padding: 12px 15px;
    border-top: 1px solid #e9e9e9;
}
.color-brand{
    color:var(--primaryColor);
}
.card{
    border:none !important;
}
.ph-val .card{
    border:none !important;
}
.ph-val .card-header{
    display: none;
}
.each-qu-ans-div{
    margin: 10px 0;
}
.a-text-bold{
    font-weight: 700;
    font-size: 14px;
    display: inline-block;
    width: 60px;
}
.a-text-regular{
    color: #007185;
    font-size: 14px;
    font-weight: 400;
    width: calc(100% - 65px);  
}
.a-text-regular-ans{
    color: #525252;
    font-size: 14px;
    font-weight: 400;
    width: calc(100% - 65px); 
}
.product-image-cart-newpage {
    width: 100%;
    height: 100px;
    object-fit: contain;
}
.continue-button{
    border: none !important;
    background-color: var(--primaryColor) !important;
    padding: 7px 10px 7px 10px !important;
    font-size: 13px !important;
    color: #fff !important;
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 600 !important;
}
.cart-item-newpage{
    padding: 11px 8px 11px 10px;
    background-color: #fff;
    
}
.product-name-cart-newpage{
    font-size: 14px!important;
    line-height: 18px!important;
    color: #525252;
}
.sub-total-right-parent{
    background-color: #fff;
}
.Subtotal{
    font-size: 18px;
    color: #525252;
}
.item-subtotal-right {
    padding: 30px 40px;
}
.Subtotal span{
    font-weight: 600;
}
.primary-button-continue{
    margin-top: 40px;
}
.prod-variant-text {
    color: #a1a1a1;
    font-size: 12px;
    line-height: 16px;
    margin: 5px 0 0;
}
.promocode-text{
    font-size: 12px;
    margin: 0px 0 10px 0;
    color: #ffffff;
    font-weight: 500;
    text-align: left;
    background-color: var(--tertiaryColor);
    text-align: center;
    padding: 12px;
}
.col8f8f8f{
    color: #525252;
}
.del-color {
font-size: 17px;
color: #525252;
cursor: pointer;
transition: 0.3s;
}
.del-color:hover{
    color: var(--primaryColor);
}
.product-price-newpage{
    font-size: 16px;
    color: #525252;
    text-align: left;
}
.font-8{
    font-size: 8px;
}
.Subtotal-mainpannel{
    background-color: #fff;
    padding: 15px 15px 25px 15px;
}
.label-wish{
    top:0;
    right:0;
    width:30px;
    height: 30px;
    box-shadow: inset 0 0 5px #d2cdcd;
    margin: 0 !important;
}
.label-wish i{
    font-size: 17px;
    padding: 4px 0;
}
.comon-arrow-style{
    position: absolute;
    font-size: 20px;
    top: 50%;
    z-index: 1;
    width: 35px;
    height: 37px;
    padding: 3px 10px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    margin-bottom: 0px !important;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    background: #FFF;
    border-radius: 3px;
    border-color: #D5D9D9;
    color: #565656;
}
.lft-pos{
    left: -22px;
}
.rig-pos{
    right:-22px;
}
.activebutton{
    display: inline-block;
}
.activebutton button{
    width:40px;
    height: 40px;
    border-radius: 50%;
    border:1px solid #000;
}
.primaryactive button{
    background-color: #000;
    color:#fff;
}

.progressbar-list::before{
    content: " ";
    background-color: rgb(155, 155, 155);
    border: 10px solid #fff;
    border-radius: 50%;
    display: block;
    width: 30px;
    height: 30px;
    margin: 9px auto;
    box-shadow: 1px 1px 3px #606060;
    transition:all;
    }
    .progressbar-list::after{
    content: "";
     background-color: rgb(155, 155, 155);
    padding: 0px 0px;
    position: absolute;
    top: 14px;
    left: -50%;
    width: 100%;
    height: 1px;
    margin: 10px auto;
    z-index: -1;
    transition: all 0.8s;
    }
    .progressbar-list.active::after{
        background-color: var(--primaryColor);
    }
    .progressbar-list:first-child::after{
        content: none;
    }
    .progressbar-list.active::before{
        font-family: FontAwesome;
        content: "\f07a";
        font-size: 15px;
        color: #fff;
        padding: 4px;
        background-color: var(--primaryColor);
        border:1px solid var(--primaryColor);
        box-shadow: 0 0 0 5px rgb(242 155 155 / 20%);
    }
    .done::before {
        font-family: FontAwesome;
        content: "\f00c";
        font-size: 15px;
        color: #fff;
        padding: 4px;
        background-color: var(--secondaryColor);
        border: 1px solid var(--secondaryColor);
        box-shadow: 0 0 0 5px rgb(242 155 155 / 20%);
    }
    .back-primary {
        background-color: var(--secondaryColor) !important;
        transform: 0.3s;
    }
    .back-primary:hover{
        border:1px solid var(--primaryColor) !important;
        /* padding: 3px 8px !important; */
        color: var(--primaryColor);
        background-color: #fff !important;
        line-height: 0;
        vertical-align: top;
        height: 35px;
    }
    .back-primary-button{
        display: inline-block;
        border: 1px solid var(--primaryColor) !important;
        color: var(--primaryColor) !important;
        padding: 7px 10px 7px 10px !important;
        font-size: 13px !important;
         background-color: #fff !important;
        vertical-align: top;
        font-weight: 600;
        border-radius: 4px !important;
    }
    .progressbar-list{
        color:#6f787d;
    }
    .active{
        color:#000;
    }
    /* card */
    .card img{
        width: 40px;
    }
    .active-card{
        color:#763cb0;
        font-weight: bold;
        border: 3px solid #763cb0;
    }
    .form-check-input:focus {
        box-shadow: none;
    }
    .bg-color-info{
        background-color:#00d69f;
    }
    .border-color{
        border-color: #ececec;
    }
    .btn{
        padding:16px 30px;
    }
    .back-to-wizard{
        transform: translate(-50%, -139%) !important;
    }
    .bg-success-color{
        background-color:#87D185;
    }
    .bg-success-color:focus{
        box-shadow: 0 0 0 0.25rem rgb(55 197 20 / 25%);
    }
    .ship-add-head{
        font-weight: 600;
        font-size: 14px;
    }
    .ship-add-head label{
        margin-bottom: 0;
    }
    .add-dtlss{
        font-size: 13px;
        margin-bottom: 0;
        color: #525252;
    }
    .search-formques{
        width:650px;
    }
    .buy-it-with ul{
        display: inline-block;
        padding-left: 0;
    }
    .buy-it-with ul li{
        list-style: none;
        display: inline-block;
        margin: 0 5px;
        font-size: 20px;
    }
    img.buy-it-img {
        width: 130px;
        height: 150px;
        object-fit: cover;
    }
    .price-color{
        color: #B12704;
        font-size: 15px;
    }
    .fs-14px{
        font-size: 14px;
    }
    .adi-info-leftpart{
        border-top: 1px solid #dddddd;
        padding: 8px 14px 6px !important;
        background-color: #e9e9e9;
        font-weight: normal;
        color: var(--tertiaryColor);
        float: none !important;
        margin-right: 0;
        text-align: center;
        width: 150px;
        font-size: 14px;
        border-bottom: 1px solid #dddddd;
    }
    .adi-info-rightpart{
        padding-top: 8px !important;
        padding-left: 14px;
        padding-bottom: 8px;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        text-align: left;
        width: inherit!important;
        font-size: 14px;
        background-color: #fff5ec !important;
    }
  .main-add-main{
      margin-bottom: 15px;
  }
    .each-address-part {
        background-color: rgb(233 233 233 / 20%);
        padding: 8px 12px;
        box-shadow: inset 0 0 1px rgb(141 141 141);
        height: 100%;
        position: relative;
    }
    /* .live-loc-div {
        padding: 15px 15px 0px 15px;
    } */
    .live-loc-div button{
        border: none;
        background-color: var(--secondaryColor);
        padding: 7px 10px 7px 10px !important;
        font-size: 13px;
        color: #fff;
        border-radius: 4px;
        font-weight: 600;
    }
    .live-loc-div button .fa{
        font-size: 18px;
    }
    .add-new-add{
        color: rgb(0 0 0 / 80%);
        font-size: 13px;
        font-weight: 600;
    }
    .add-new-add .fa{
        font-size: 15px;
    }
.bgpinbutton {
    border: none !important;
    background-color: var(--primaryColor) !important;
    padding: 7px 10px 7px 10px !important;
    font-size: 13px !important;
    color: #fff !important;
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 600 !important;
    transition: 0.3s;
}
.bgpinbutton_nocolor {
    border: none !important;
    padding: 7px 10px 7px 10px !important;
    font-size: 13px !important;
    color: #fff !important;
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 600 !important;
    transition: 0.3s;
}
.bgpinbutton:hover{
    background-color: var(--primaryColor) !important;
}
.bgpinbutton:focus{
    box-shadow: none !important;
}
.add-new-add-link{
    font-size: 12px !important;
    margin-left: 10px;
    color: #0066C0;
}
.itemlistinner {
    box-shadow: 0 0 4px #f3eaea;
}
.form-group-labels{
    font-size: 13px;
    color: #525252;
    margin-bottom: 3px;
}
.form-group-mb-cont{
    margin-bottom: 7px !important;
}
.form-group-mb-cont .form-control{
    font-size: 13px;
}
.min-w-save-cancelo{
    min-width:70px !important;
}
.cancel-light-button{
    border: 1px solid var(--primaryColor) !important;
        color: var(--primaryColor) !important;
        padding: 6px 10px 6px 10px !important;
        font-size: 13px !important;
         background-color: #fff !important;
        vertical-align: top !important;
        font-weight: 600 !important;
        border-radius: 4px !important;
}
.selected-address{
    font-size: 13px;
    color: #525252;
    margin-top: 3px;
}
.progressbar-list{
    font-size:13px;
}
.product-imagefinalorber{
    width:50px !important;
    height: 70px;
    object-fit: contain;
}
.jc-flc{
    background-color: #f9f9f9;
}
.final-ordcol{
    border: 1px solid #ededed;
    padding: 0;
}
.asin-title{
    font-size: 13px;
    line-height: 19px;
    color: #525252;
    font-weight: 600;
    margin-bottom: 0;
}
.my-items-header{
    background-color: #1b1b1b;
    padding: 10px 15px 10px 15px;
    color: #fff;;
}
.show-more-less-footer{
    background: rgb(181 181 181 / 20%);
    padding: 4px 0;
}
.show-more-less-footer button{
    color: #000 !important;
    font-weight: 600 !important;
    padding: 4px !important;
    text-decoration: none !important;
}
.gtotal-section{
    padding: 20px 20px !important;
    background: #fff;
}
.p-derails-header{
    font-weight: 600 !important;
    margin: 0;
    padding: 0px 0 7px 0;
    border-bottom: 1px solid #dadada;
    font-size: 15px;
    margin-bottom: 15px;
}
.addressbrands-secondallpages{
    font-size: 13px;
    color: #000;
    margin-bottom: 7px;
}
.feature-save-chkbox{
    font-size: 12px;
    margin-top: 8px;
    color: rgb(109 135 151 / 80%);
}
.a-checkbox-label{
    position: relative;
    top: -2px;
}
.min-same-widthxs{
    min-width: 100px;
    height: 35px;
    line-height: 0;
}
#Next:disabled{
    cursor: no-drop;
}
.shield-did {
    font-size: 14px;
    text-align: left;
    display: inline-block;
    color: #878787;
    font-weight: 500;
}
.shield-did .fa{
    font-size: 30px;
}
.shield-did{
    width: 35px;
    vertical-align: top;
    text-align: center;
    padding-right: 10px;
}
.shild-txt{
    width: 87%;
    vertical-align: top;
    font-size: 12.5px;
    font-weight: 500;
    color: #878787;
}
.pay-card-body{
    background: rgb(245 245 245 / 30%);
    border-radius: 0;
    border: none;
    box-shadow: 0 0 5px rgb(218 218 173 / 70%);
}
.form-check-hcont{
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    height: 50px;
}
.im-w-55{
    width: 55px;
}
.slick-next{
    right: -30px !important;
    z-index: 9 !important;
}
.slick-prev:before, .slick-next:before{
    color: #000 !important;
    font-size: 25px;
    position: absolute;
    top:30px;
    left:5px !important;
}
/* .slick-prev:before{
    background-image: url(../img/next.png) !important;
} */
.header-top-leftcontact-section{
    display: flex;
}
.header-top-leftcontact-section a{
    margin-bottom: 0;
    font-size: 13px;
    margin-right: 20px;
    color: #525252 !important;
    text-decoration:none !important;
}
.header-top-leftcontact-section i{
    font-size: 14px;
    /* color: var(--primaryColor); */
    color:#525252;
}
.header-top-rightcontact-section{
    display: flex;
    justify-content: end;
}
.right-sm-ul{
    list-style: none;
    margin-bottom: 0;
}
.right-sm-ul li a{
    font-size: 13px;
    color: #525252;
    text-decoration: none !important;
    transition: 0.3s;
    padding: 0 12px;
}
.right-sm-ul li a:hover{
    color: var(--primaryColor);
}
.header-bottom-rightcontact-section a{
    /* background-color: var(--primaryColor); */
    background-color: #525252;
    padding: 3px 7px;
    border-radius: 50%;
    font-size: 10px;
    color: #fff !important;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 12px;
    transition: 0.3s;
}
.header-bottom-rightcontact-section a:hover{
    background-color: var(--tertiaryColor);
}
.right-sm-ul li{
    float: left;
}
.header-second{
    display: flex;
}
.header-secondlogo{
    flex-grow: 1;
}
.header-twomenu {
    padding: 3px 0;
    list-style: none;
    margin: 0;
    border-right: 1px solid #e2e2e2;
    margin: 5px 0 5px 20px;
}
.header-twomenu li {
    display: inline-block;
}
.header-twomenu li a{
    color: #525252;
    padding: 10px 24px 15px 0px;
    font-size: 14px;
    text-decoration: none !important;
}
.header-twomenu li a:hover{
    color: var(--primaryColor);
}
.active-head-labelmenu{
    color: var(--primaryColor) !important;
}
.elementor-button {
    font-family: "Roboto", Sans-serif;
    font-size: 13px;
    font-weight: 400;
    background-color: var(--primaryColor);
    border-radius: 27px 27px 27px 27px;
    padding: 8px 15px 8px 15px;
    color:#fff !important;
    text-decoration: none !important;
    transition: all .3s;
}
.elementor-button:hover{
    background-color: var(--tertiaryColor);
}
.comm-marclass{
    margin: 8px 0;
}
.activ-user{
    color: var(--primaryColor) !important;
    font-size: 20px;
}
.search-icon{
    position: absolute;
    width: 18px;
    left: 13px;
    top: 12px;
    z-index: 9;
}
.search{
    position: relative;
    margin-left:0px;
}
.nonactvuser{
    font-size: 19px;
}
.tada:hover{
    fill: var(--primaryColor);
}
.nonactvuser:hover{
    color: var(--primaryColor);
}
.wish-heart{
    font-size: 19px;
    color: #525252;
}
.wish-heart:hover{
    color: var(--primaryColor);
    cursor: pointer;
}
.log-user i{
    color: #525252;
}
.cart-icon svg{
    scale: 1.3;
}
.user-icon{
    width: 25px;
    height: 25px;
}
.user-icon:hover{
    fill: var(--primaryColor);
}
.user-icon path{
    scale: 0.22;
}
.profile-each-list{
    padding: 8px 15px !important;
}
.profile-each-list a:hover{
    color: var(--primaryColor) !important;
}
.profile-each-list a{
    color: #525252 !important;
    display: block;
}
.profile-each-list a:hover{
    color: var(--primaryColor);
    cursor: pointer;
}
.m-cont-l-r {
    padding: 8px 12px;
    margin: 0;
}
.m-cont-l-r-last{
    margin:8px 0px 8px 30px
}
.footer-wrap-top{
    background-color: #222222;
    padding: 30px 0px 15px 0px;
    margin-top: 50px;
}
.wdhet-div{
    display: flex;
}
.wd-para h3{
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 25px;
}
.wd-para p{
    color:#BCBCBC;
    font-size: 13px;
}
.wdicon i{
    color: #585858;
    font-size: 37px;
}
.wdicon{
    padding: 10px;
}
.wd-para {
    padding: 10px;
}
.first-left-footer p{
    margin: 0;
    text-align: left;
    font-size: 13px;
    color: #808080;
    padding: 15px 0;
}
.f-social-logo{
    margin: 0;
    padding: 0;
    list-style: none;
}
.f-social-logo li{
    display: inline-block;
    margin: 3px;
    padding: 0;
}
.f-social-logo li a {
    width: 30px;
    height: 30px;
    background: #929292;
    color: #1b1b1b;
    border-radius: 50%;
    padding: 3px 8px;
    font-size: 18px;
    display: inline-block;
    font-size: 18px;
    transition: 0.3s;
    margin-right: 15px;
}
.f-social-logo li a:hover{
    background-color: #f2f2f2;
}
.f-social-logo li a .fa-facebook{
    padding-left: 1px;
}
.f-social-logo{
    margin: 25px 0 35px 0;
}
.widget-title-footer{
    font-weight: 400;
    color: #efefef;
    letter-spacing: 1px;
    padding-left: 15px;
    margin: 0 0 20px;
    text-transform: capitalize;
    display: block;
    line-height: 1;
    font-size: 14px;
    border-width: 0 0 0 3px;
    border-style: solid;
    border-color: var(--primaryColor);
}
.menu-footer-menu-2-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.menu-footer-menu-2-container ul li{
    padding: 6px 0;
}
.menu-footer-menu-2-container a {
    color: #929292;
    font-size: 14px;
    text-decoration: none !important;
    transition: 0.3s;
}
.menu-footer-menu-2-container a:hover{
    color:var(--primaryColor);
}
.footer-widget ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.contact-info-widget li {
    display: inline-block;
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    border: 0;
}
 .oceanwp-info-wrap {
    padding-left: 55px;
}
.oceanwp-contact-title {
    color: #777;
    font-weight: bold;
    line-height: 1.4;
    display: block;
}
.oceanwp-contact-text {
    line-height: 18px;
    font-size: 13px !important;
    color: #808080 !important;
    padding: 15px 0;
    text-decoration: none !important;
}
.oceanwp-info-wrap {
    padding-left: 27px;
    display: inline-block;
    width: 85%;
    vertical-align: top;
}
.address i{
    font-size: 18px;
    color: var(--primaryColor);
    width: 16px;
}
.mobile i{
    font-size: 18px;
    color: var(--primaryColor);
    width: 16px;
}
.email i{
    font-size: 13px;
    color: var(--primaryColor);
    width: 16px;
}
.oceanwp-contact-text a{
    color: #929292;
    font-size: 14px;
    text-decoration: none !important;
    transition: 0.3s;
}
a.oceanwp-contact-text:hover{
    color: var(--primaryColor) !important;
}
.our-com-name{
    color: var(--primaryColor) !important;
}
.footer-small-menu {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    float: right;
}
.footer-small-menu li {
    float: left;
    border-right: 1px solid #929292;
    padding-right: 7px;
    line-height: 12px;
    padding-left: 7px;
    padding-top: 5px;
}
.footer-small-menu li:last-child{
    border-right:none;
}
.footer-small-menu li a {
    font-size: 13px;
    color: #e0e0e0;
    text-decoration: none;
    transition: 0.3s;
}
.footer-small-menu li a:hover{
    color: var(--primaryColor);
}
.copyright-small small{
    font-size: 13px;
    color: #929292;
    text-decoration: none;
}
.footer-last-bottom {
    padding: 40px 0;
    border-top: 1px solid #444;
}
.footer-small-menu li:first-child{
    padding-left: 0;
}
.Banner-crousal  .control-arrow{
    opacity: 1 !important;
}

.oceanwp-info-wrap {
    padding-left: 20px;
}
.slick-prev, .slick-next{
    width:30px !important;
    height: 90px !important;
    background: #e7e7e7 !important;
    transition: 0.3s;
}
.slick-prev:hover, .slick-next:hover{
    background: #aba8a8 !important;
}
.slick-prev {
    left: -30px !important;
    z-index: 9;
}
.slick-prev:before{
    content:url('../img/prv-ar-drk.png') !important; 
    border:none !important;
    opacity: 1 !important;
}
.slick-next:before{
    content:url('../img/next-arr-drk.png') !important; 
    border:none !important;
    opacity: 1 !important;
}
.Banner-crousal .carousel .control-next.control-arrow:before{
    content:url('../img/next-arr.png') !important; 
    border:none !important;
}
.Banner-crousal .carousel .control-prev.control-arrow:before{
    content:url('../img/prv-ar.png') !important; 
    border:none !important;
}
.contact-info-widget.default {
    padding-left: 20px;
}
.address{
    padding-top: 6px !important;
}
.oceanwp-contact-text-full-width{
    display: block;
    padding: 5px 0;
}
.bg-light-control{
    background-color: #fff;
}
.dTqBif {
    position: relative;
    width: 100%;
    height: 150px !important;
}
.page-lab-head {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    text-align: left;
    padding: 15px 0px 15px 0px;
    margin: 0px;
    color: #525252;
    letter-spacing: 1px;
    /* border-bottom: 1px solid #a1a1a1; */
}
.product-details-maindiv {
    margin-bottom: 10px;
}
.product-codes {
    color: #959595;
    margin: 0;
    font-size: 14px;
}
.sub_submenu{
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
    text-transform: capitalize;
    color: rgb(3 2 26 / 60%) !important;
    /* margin-bottom: 8px;
    margin-top: 8px; */
    text-decoration: none !important;
    padding-left: 15px;
    display: block;
}
.subsubmenuul{
    display: none;
    transition: 0.4s;
}
.subsubmenumaindiv{
    padding: 6px 0;
}
.subsubmenumaindiv:hover .subsubmenuul{
    display: block;
} 
.subsubmenumaindiv:hover .roticon{
    transform: rotate(90deg);
}
.subsubmenuul{
    padding-left: 15px !important;
}
.roticon{
    font-size: 11px;
}
.cpointer{
    cursor: pointer;
}
.log-personname{
    font-size: 15px;
    margin-left: 6px;
    color:#525252;
    line-height: 28px;
    font-weight: 600;
}
.activ-Address{
    border: 1px solid var(--primaryColor);
    box-shadow: inset 0 0 5px var(--primaryColor);
}
.activ-Address .bgpinbutton{
    cursor: no-drop !important;
    background-color: var(--tertiaryColor) !important;
}
.old-typemenu{
    position: absolute;
    min-width: 200px;
    left: 0;
    top: 59px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(218 218 218 / 40%);
}
.custom-modal-header{
    background-color: #000 !important;
}
.form-control{
    height: 35px !important;
    outline: none !important;
}
textarea.form-control{
    height: auto !important;
}
.thanks-modtitle{
    font-size: 20px !important;
    color: #ffff;
    padding: 0;
    font-weight: normal;
}
.custom-modal-header button.close{
    color:#fff !important
}
.close{
    color:#fff !important;
    opacity: 1 !important;
}
.fs-14px-desh{
    font-size: 14px;
}
.ASSSS .megaDropdown{
    display: block !important;
}
.ASSSS .roticon{
    transform: rotate(90deg) !important;
}
.PhoneInputInput {
    height: 35px !important;
    border-radius: 3px !important;
    outline: none !important;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.PhoneInputCountryIconImg{
    margin-left: -8px;
}
.PhoneInputInput{
    height: 40px !important;
    border-radius: 0 !important;
    font-size: 14px !important;
}
button.close{
    color:#000 !important;
}
.item-on-div{
    margin-bottom: 15px;
    border: 1px solid #f9f0f0;
    border-radius: 2px;
}
.prod{
    position: relative;
    height: 200px;
}
.bottom-item-inner{
    position: relative;
}
.p-abcenterbutton{
    /* position: absolute; */
    width:100%;
    /* display: flex; */
    justify-content: center;
    flex-direction: column;
    height: 100%;
    bottom: 0;
    /* background-color: #222222; */
    align-items: center;
    /* display: none; */
    transition:0.5s;
}
.bottom-hov-div{
    position: relative;
}
.item-on-div:hover{
    background: rgb(251 251 251 / 50%);
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(72 72 72 / 30%);
    cursor: pointer;
    position: relative;
}
.to-continue-button{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}
.header_scroller_width{
    width:415px
}
.linecount-text{
   display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  font-size: 15px;
}
.prod-desription-twoline{
    font-size: 13px;
    margin-bottom: 10px;
    -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  display: -webkit-box;
}
.ph-addbutton{
    background-color: var(--primaryColor) !important;
    border: none;
    width: 70px;
    padding: 5px 0;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
}
.midLinks a{
    color: var(--primaryColor) !important;
}
.midLinks {
    font-size: 13px;
    color: rgba(40,44,63,.8);
}
.cl-btn-white .close{
    color: #fff !important;
}
.item-on-div:hover .prod-img{
    transform: scale(1.08);
}
/* .item-on-div:hover .primary-button{
    background-color: var(--tertiaryColor) !important;
} */
.item-on-div .primary-button:hover{
    background-color: var(--tertiaryColor) !important;
}
.megamenu-section{
    margin-top: 0px;
    padding: 10px 0;
    background: #fff;
    /* border-bottom: 1px solid #e2e2e2;
    box-shadow: -1px 3px 8px -4px #c3c1c1; */
    /* display: none; */
}
.mega-menu-hoverfull{
    position: fixed;
    left: 0;
    z-index: 9;
    top: 0;
    margin-top: 120px; 
    width:100%;
    background:rgb(0 0 0 / 20%);
    padding-bottom: 15px;
    display: none;
    
}
.megamenu-section-inner{
    padding: 0 15px;
   
}
.megamenu-section-inner ul{
    padding-left:0;
    margin-bottom: 0;
}
.megamenu-section-inner ul li{
    list-style: none;
    display: inline-block;
    position: relative;
}
.megamenu-section-inner ul li a{
    color: rgba(3, 2, 26, 0.5);
    font-size: 15px;
    text-decoration: none;
    padding: 18px 25px 27px 10px;
    transition: all 0.3s;
    font-weight: 600;
}
.main-flex-eachdiv:nth-child(even) {
    background: #f7f7f7;
    /* height: 100%; */
}
.category_without_item a{
    color: #03021acc !important;
    font-size: 14px !important;
}
.category_without_item a:hover{
    color: var(--primaryColor) !important;
}


.all-megamenu-list-div{
    background-color: #fff;
    height: 430px;
    overflow: auto;
    margin-bottom: 0px;
    padding: 15px 0px 30px 0px;
    text-align: left; 
    border-top: 1px solid #f5f2f2;
}
.main-flex-menulist{
    /* display: flex;
    flex-wrap: wrap; */
    height: 100%;
    overflow: auto;
}
.main-flex-eachdiv{
    /* flex-grow: 1;
  width: 25%; */
  /* margin-bottom: 10px; */
}
.main-flex-eachdiv h3{
    font-size: 14px;
    color:#03021acc !important;
    margin: 10px 0 6px 0;
    font-weight: 600;
}
.main-flex-eachdiv ul{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.main-flex-eachdiv ul li{
    display: block;
    line-height: 16px;
    margin-bottom: 8px;
}
.main-flex-eachdiv ul li a{
    font-size: 14px;
    padding: 0;
    /* line-height: 22px; */
    transition: all 0.3s;
    font-weight: normal !important;
}
.main-flex-eachdiv ul li a:hover{
    color: var(--primaryColor);
}
.megamenu-section-inner ul li a:hover{
    color: var(--primaryColor);
}
.add-new-add-link{
    color: var(--primaryColor) !important;
    outline: none !important;
    border:none !important;
    background-color: transparent !important;
}
.megamenu-item:hover .mega-menu-hoverfull{
    display: block;
}
.second-label-arrow{
    display: none;
}
.product-names .fa-info-circle{
    font-size: 16px;
    color: #525252;
}
.footer-dynamiclogo{
    width: 200px;
}
.app-label-text{
    font-size: 15px;
}
.add-item-cart-label{
    font-size: 13px;
    font-style: italic;
    color: #2eb72e;
    font-weight: 500;

}
.add-item-cart-label svg{
    fill:#2eb72e;
    width: 25px;
    height: 22px;
    margin-right: 6px;
}
h3.product-names{
    margin-bottom: 15px;
    word-break: break-all;
}
.main-price{
    font-weight: 600;
    font-size: 27px;
}
.mrp_price {
    opacity: 45% !important;
    font-size: 20px;
    color: #040404;
}
.main-megamenu{
    color: #525252 !important;
}
.main-megamenu:hover{
    color: var(--primaryColor) !important;
}
.only-mega-noarrow{
    color: #525252 !important;
}
.only-mega-noarrow:hover{
    color: var(--primaryColor) !important;
}
.w-100-hcontrol{
    height: 280px;
    width: 100%;
    object-fit: contain;
}
.rounded-dotted-button{
    border: 1px solid var(--primaryColor) !important;
    color: var(--primaryColor) !important;
    height: 32px;
    padding: 0px 15px !important;
    border-radius: 50px !important;
    background: transparent !important;
    font-weight: 600 !important;
    outline: none !important;
    box-shadow: none !important;
}
.only-freatures-product .addtocart-div{
    display: none;
}
.only-freatures-product .price-new{
    display: none;
}
.only-freatures-product .item-on-div{
    border:none
}
.only-freatures-product .item-on-div:hover{
    border:none;
    box-shadow: none;
    background-color: transparent;
}
.Toastify__toast--info{
    background-color: var(--primaryColor) !important;
}
.megamenu-section-inner ul li:hover .main-megamenu{
    color: var(--primaryColor) !important;
}
.out-of-stock{
    display: flex;
    position: absolute;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 15px);
    width: calc(100% - 30px);
    z-index: 1;
    background: rgb(0 0 0 / 70%);
    color: #fff;
    font-weight: 600;
}
.item-on-div:hover .out-of-stock{
    width: 100%;
    height: 100%;
}
.stock-out-cart{
    margin: 0;
    color: red;
    font-style: italic;
    display: inline-block;
    font-weight: 600;
    font-size: 15px;
    margin-right: 5px;
}
.del-text {
    color: var(--primaryColor) !important;
    font-style: italic;
   
    font-size: 15px;
    margin-top: 20px;
}
.del-text .fa{
    font-size: 20px;
    margin-right: 10px;
}
.accept-label{
    margin-top: 25px;
}
.accept-label a{
    color: var(--primaryColor) !important;
    text-decoration: none;;
}
.outstock-details{
    color:var(--primaryColor) !important;
    margin: 10px 0;
    font-size: 28px;
}
.buy-now-addcart{
    margin: 5px 10px;
}
.accept-labelmain {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .accept-labelmain input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .ckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border:1px solid  #bfbebe;
  }
  
  /* When the checkbox is checked, add a blue background */
  .accept-labelmain input:checked ~ .ckmark {
    background-color: #bfbebe ;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .ckmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .accept-labelmain input:checked ~ .ckmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .accept-labelmain .ckmark:after {
    left: 5px;
    top: 2px;
    width: 7px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }


  .ckmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .accept-labelmain input:checked ~ .ckmark:after {
    display: block;
  }
  .for-indiperstyle-menu{
    float: none;
    /* padding-left: 50px; */
    width: 605px;
  }
  .d-noneclass{
    display: none !important;
  }
  .megamenu-item:hover .sub-menus-new{
    display: block;
  }
  .sub-menus-new {
    position: absolute;
    width: 250px;
    min-height: 100px;
    background: #ffffff;
    left: 0;
    top: 48px;
    box-shadow: 0 0 5px #dadada;
    border-top: 4px solid var(--primaryColor) !important;
    text-align: left;
    padding: 5px 10px;
}
.sub-menus-new-2 {
    position: absolute;
    width: 250px;
    min-height: 100px;
    background: #ffffff;
    left: 0;
    top: 48px;
    box-shadow: 0 0 5px #dadada;
    border-top: 4px solid var(--primaryColor) !important;
    text-align: left;
    padding: 5px 10px;
    display: none;
}
.single-label-dropdown li{
    display: block !important;
    padding: 10px 0px 10px 3px !important;
    position: relative !important;
    border-bottom: 1px solid #f9f4f4;
    transition: 0.3s;
}
.single-label-dropdown li:last-child{
    border-bottom: none;
}
.single-label-dropdown li a {
    padding: 0 0 0 0 !important;
    color: rgba(3,2,26,.8)!important;
    font-size: 14px !important;
    display: block;
    font-weight: 600;
}
.single-label-dropdown li a:hover{
    color: var(--primaryColor) !important;;
}
/* .single-label-dropdown ul{
    position: absolute;
    left: 230px;
    top: 0;
    float: right;
    background: #fff;
    width: 170px;
    border: 1px solid #f3f3f3;
    box-shadow: 0 0 5px #efefef;
    padding: 5px 5px 5px 5px;
    border-top: 3px solid var(--primaryColor) !important;
} */
.single-label-dropdown ul{
    background: #fff;
    width: 100%;
    padding: 5px 5px 5px 5px;
}
.single-label-dropdown ul li{
    padding: 8px 8px !important;
    border-bottom: none !important;
}
.single-label-dropdown ul li a{
    font-size: 12px !important;
    color: rgba(3,2,26,.7) !important;
    font-weight: normal;
}
.single-label-dropdown ul li a:hover{
    color: var(--primaryColor) !important;;
}
.mega-arrow-svg-sub{
    width: 12px;
    float: right;
    transform: rotate(270deg);
    margin-top: 5px;
}
.sub-subs-menus-ul{
    
   /* position: absolute;
    left: 232px;
    top:10px;
    box-shadow: 0 0 5px #dadada;
    z-index: 99;
    width: 180px !important;
    border-top: 3px solid var(--primaryColor) !important; */
}
.sub-subs-menus:hover .sub-subs-menus-ul{
    display: block;
}
/* .sub-subs-menus:hover .mega-arrow-svg-sub{
    transform: rotate(180deg);
    top:0;
} */
.custom-accordian .accordion__item{
    margin-bottom: 15px;
}
.custom-accordian .accordion__panel{
    background-color: #fff;
    border: 1px solid #f3efef;
    border-top: none;
}
.custom-accordian .accordion__button {
    background-color: #ffffff !important;
    color: #444;
    cursor: pointer;
    padding: 12px 18px;
    width: 100%;
    text-align: left;
    box-shadow: 0 0 5px #dadada;
    font-weight: 600;
    position: relative;
}
.custom-accordian .accordion__button:focus-visible{
    outline: none;
}
.custom-accordian .accordion__button:before{
    position: absolute;
    right:18px;
    top:18px;
}
.style-control-static h3{
    color: #404040;
    font-size: 25px;
}
.style-control-static p{
    margin-bottom: 5px;
    font-size: 14px;
    color: #7a7a7a;
}
.style-control-static li{
    margin-bottom: 5px;
    font-size: 14px;
    color: #7a7a7a;
}
.contact-box{
    background: #fff;
    padding: 30px 30px;
    box-shadow: 0 0 5px #dadada;
    border-radius: 5px;
}
.contact-box p{
    font-size: 18px;
    color: #5D5D5D;
}
.cus-font-icon{
    font-size: 25px;
    width: 35px;
}
.label-address {
    font-size: 15px;
    color: #766d6d;
    padding-left: 35px;
    display: block;
}
.comon-label-div{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.comon-label-div h1{
    color:#fff;
    font-weight: 600;
    font-size: 50px;
}
.comon-label-div p{
    color:#fff;
    font-size: 20px;
}
.about-first-section{
    background-color: #fff;
    border:1px solid #dadada;
    border-radius: 15px;
    padding: 40px 50px 60px 50px;
}
.about-first-section h1{
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 35px;
}
.why-us h2{
    color: #282727;
    font-size: 36px;
    font-weight: 700;
}
.why-us p{
    color: #767676;
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 0.3px;
}
.mison-vision h2{
    position: relative;
    padding: 5px 0px 5px 15px;
    border-style: solid;
    border-width: 0px 0px 0px 5px;
    border-color: var(--primaryColor) !important;
}

.mison-vision p{
    font-size: 17px;
    line-height: 23px;
    color: #54595F;
}
.mbo-style-margin-padding{
    margin-top: 50px;
    margin-bottom: 30px;
    padding-top: 50px;
    padding-bottom: 00px;
}
.inline-wishlistdiv{
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    color: var(--primaryColor) !important;
    font-size: 20px;
}
.bg-control-wishlist{
    background-color: #fff;
    border: 1px solid #dee2e6!important;
}
.del-color-light{
    color: #a7a7a7;
    font-size: 20px;
}
.wishlist-value{
    font-size: 15px;
}
.star-div .fa{
    margin-right: 5px;
    color: var(--primaryColor) !important;
}
.list-wishlist{
    border-bottom: 1px solid #dadada;
    text-align: right;
    padding: 10px 5px;
    background: #444;
}
.third-type-buuton-with-icon{
    color: #ffffff !important;
    border: none;
    background-color: transparent;
    font-size: 14px; 
}
.third-type-buuton-with-icon:hover{
    color:#f6f6f6 !important;
}
.third-type-buuton-with-icon .fa{
    font-size: 20px;
    margin-right: 5px;
    position: relative;
    top:3px;
}
a.nav-link-new.nav-link-new-wishlist-name.nav-link {
    font-size: 15px !important;
    color: #333 !important;
}
.bg-theme{
    background-color: var(--primaryColor) !important;
}
.alert-wishlist .fa{
    color: #fb4b4b !important;
    font-size: 20px;
    margin-bottom: 6px;
}
.alert-wishlist .wishtlist_header {
    color: #fb4b4b !important;
    font-weight: normal !important;
    font-style: italic;
}
/* .small-banner{
    margin-bottom: 20px;
}
.medium-banner{
    margin-bottom: 20px;
} */


.garment-header{
    text-transform: capitalize;
    font-size: 15px;
    color: #707070;
}
.garment-type-inner{
    position: relative;
    overflow: hidden;
    height: 100%;
    transition: 0.5s;
    cursor: pointer;
}
.garment-type-inner:hover{
    transition: 0.5s;
}
.abs-img:hover{
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    transition: 0.5s;
}
.small-banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.small-banner-text h1{
    color: #fff;
    margin-bottom: 0;
}
.small-banner-text p{
    color: #fff;
    font-style: italic;
}
.medium-banner-text{
    color: #fff;
    margin-bottom: 0;
}
.medium-banner-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.medium-banner-text h1{
    color: #fff;
    margin-bottom: 0;
}
.full-page-body-announce{
    padding-top: 150px;
}
.announcement{
    padding: 5px 15px;
}
.announcement p{
    margin-bottom: 0;
    font-size: 13px;
}
.full-Pagesection-announce{
    padding-top: 150px;
}
@media (min-width: 576px){
.login-regmodal .modal-dialog {
    max-width: 430px;  
}
}
.br-radious-0{
    border-radius: 0 !important;
    height: 40px !important;
}
.login-regmodal .modal-content{
    min-height: 400px;
    border-radius: 0;
}
.log-sign-head {
    padding: 0 35px;
    font-size: 19px;
    color: #585858;
}
.phno-lebel{
   color: rgba(40,44,63,.8);
}
.primarybutton{
    background-color:var(--primaryColor) !important;
}
/* -------------------------------------------------Responsive css Start--------------------------------- */
@media (max-width: 767px){
    .bg-control-wishlist{
        margin-bottom: 30px;
    }
  
    .text-left-mob{
        text-align: left !important;
    }
    .d-inb-mob{
        display: inline-block;
        vertical-align: top;
    }
    .del-color.Remove-item-mobile{
        margin-top: 0 !important;
        margin-left: 8px !important;
    }
    .search-form .search-keyword{
        width:100% !important;
    }
    .min-same-width {
        min-width: 90%;
    }
    .comon-label-div{
        width: 100%;
        padding: 30px 30px;
    }
    .mbo-style-margin-padding {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }
    .out-of-stock{
        width: 100%;
    }
    .mobile-style-nofixed-button{
        display: flex;
        text-align: right;
        background: #fff;
        padding: 7px 7px;
    }
    .mobile-style-nofixed-button .min-same-width{
        min-width: 48%;
        margin: 0 1% !important;
        border-radius: 0;
    }
    .mobile-style-fixed-button .decrement{
        height: 42px;
        padding: 8px 9px;
    }
    .mobile-style-fixed-button .increment{
        height: 42px;
        padding: 8px 9px;
    }
    .mobile-style-fixed-button .stepperc-input{
        width:100%;
    }
    .mobile-style-nofixed-button .stepperc-input{
        width:100%;
        text-align: center !important;
    }
    .mobile-style-fixed-button .quantity{
        height: 42px;
    }
    .item-on-div:hover .primary-button{
        background-color:var(--primaryColor) !important;
    }
    .me-auto-mobile .dropdown-menu{
        border: none;
        padding: 0;
    }
    .mt-5-mobile{
        margin-top: 20px !important;
    }
    .to-continue-button .min-same-widthxs {
        min-width: 70px;
    }
    .min-same-widthxs{
        min-width: 70px;
    }
    .nav-dropdown-first-mobile.show:before {
        content: " ";
        width: 3px;
        height: 41px;
        position: absolute;
        background-color: var(--primaryColor);
        top: 0px;
        left: 0;
        opacity: 0.2;
    }
    .nav-dropdown-first-mobile.show > a{
        position: relative;
    }
    .nav-dropdown-first-mobile.show > a:before{
        background-color: var(--primaryColor);
        content: " ";
        width:100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0;
        opacity: 0.04;
    }
    .nav-dropdown-first-mobile a{
        color: #343434 !important;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 15px !important;
        border-bottom: 1px solid #f1f1ee;
    }
    a.secon-label-menu-mobile {
        padding: 10px 30px !important;
        font-weight: normal;
        font-size: 15px !important;
        color: #5e5e5e !important;
    }
    .nav-drop-second-label-mobile a {
        padding: 8px 30px !important;
        border-bottom: none !important;
        font-size: 15px !important;
        color: #5e5e5e !important;
        font-weight: normal;
    }
    .navlink-mobile-onlymenu{
        padding: 10px 15px !important;
        color: #343434 !important;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px solid #f1f1ee;
    }
    .dropdown-toggle::after{
        border:none !important;
        background-image: url(../img/next-arr-drk-old.png);
        width: 18px;
        height: 18px;
        content: " " !important;
        position: absolute;
        z-index: 99;
        background-position: bottom;
        right: 12px;
        /* background: red; */
        background-repeat: no-repeat;
        top: 7px;
        background-size: 11px;
    }
    .nav-drop-second-label-mobile .dropdown-toggle::after{
        opacity: 0.5 !important;
    }
    .nav-drop-second-label-mobile.show > .dropdown-toggle::after{
        transform: rotate(90deg);
        top:10px;
    }
    .nav-dropdown-first-mobile.show > .dropdown-toggle::after{
        transform: rotate(90deg);
        top:10px;
    }
    .dropdown-menu.show .nav-dropdown-thitd-mobile{
        padding-left: 45px !important;
        font-size: 13px !important;
        color:#979797 !important;
        white-space: normal;
        padding: 5px 30px 5px 40px !important;
        font-weight: normal;
    }
    .hamberger-footer{
        position: fixed;
        bottom: 0;
        width: calc(100% - 50px);
        background: #f5f5f5;
        padding: 10px 15px;
        border-top: 1px solid #efefef;
        max-width: 400px;
    }
    #google_translate_element{
        left:0;
        right: initial;
    }
    .goog-te-combo{
        font-size: 11px;
    }
    .goog-logo-link, .goog-logo-link:link{
        font-size: 11px;
    }
    .d-block-mob{
        display: block;
    }
    h3.product-names{
        font-size: 25px;
    }
    .ml-0-mobile{
        margin-left: 0 !important;
    }
    .add-button .primary-button{
        margin-bottom: 10px;
    }
    .add-button .secondary-round-button{
        margin-bottom: 10px;
    }
    .buy-now-addcart {
        margin: 5px 10px 10px 10px !important;
    }
    .w-100pxl{
        width:100px;
    }
    .hovdropdowndiv {
        width: 130px !important;
        right: -55px !important;
        top: 35px !important;
        left: unset !important;
    }
    .log-personname-mobile:hover .hovdropdowndiv{
        display: block;
        z-index: 999;
    }
    .hover-submenu .d-block-only-log{
        display: none !important;
    }
    
    .footer-last-bottom .text-cen-mob{
        text-align: center !important;
    }
    .mob-menu-footer-sm-bannerdiv{
        height:115px;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        background-color:#fff;
        padding:8px 0;
    }
    .mob-menu-footer-sm-bannerdiv:after{
        position: absolute;
        content: " ";
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        opacity: 0.2;
        left: 0;
        top: 0;
    }
    .megamenu-section-inner{
        height: calc(100% - 115px);
    overflow: auto;
    }
    .avl-in a .fa-info-circle{
        font-size: 16px;
        color: #525252;
    }
    .product-names .fa-info-circle{
        font-size: 16px;
        color: #525252;
    }
    .hamberger-footer a{
        width: 27px;
        height: 27px;
        font-size: 16px;
    }
    .megamenu-section-inner{
        padding: 0;
    }
    .megamenu-section-inner ul li{
        display: block;
        border-bottom: 1px solid #e9e8e8;
        padding: 8px 15px;
    }
    .megamenu-section-inner ul li a{
        padding: 0;
        line-height: 30px;
        font-weight: 600;
        font-size: 15px;
    }
    .main-flex-eachdiv ul li{
        line-height: 20px;
    }
    .all-megamenu-list-div{
        padding: 0;
        width:100%;
        height: auto;
    }
    .main-flex-menulist{
        display: block;
    }
    .main-flex-eachdiv{
        width:100%;
    }
    .megamenu-section{
        width:100%;
        /* display: none; */
        height: calc(100vh - 150px);
        overflow: auto;
        padding-top: 0px;
    }
    .mobile-category-menupannel .txt-left-mob{
        text-align: left !important;
    }
    .header_scroller_width{
        width:100%
    }
    .full-header{
        left: 0;
        top:0
    }
    .w-60mob{
        width:60px;
        position: absolute;
        z-index: 999;
        top: 0;
        left: 80px;
    }
    .slick-next {
        right: -7px !important;
    }
    .slick-prev {
        left: -7px !important;
    }
    .pt-2-cus{
        font-size: 15px;;
    }
.brand .logo {
    width: 80px;
    margin: 2px 0;
    height: auto;
}
.border-none-mob{
    border:none !important
}

.mobile-search-pannel{
    display: block;
}
.topsection-header{
    height: auto;
    width:100%;
    display: flex;
    justify-content: center;
    flex-direction: inherit;
    padding-bottom: 4px;
    padding-top: 4px;
}
.search-path{
    stroke: #525252;
}
.mob-category .categories-section{
    width: 70%;
    height: calc(100vh - 103px);
    display: block;
    background: #fff;
    padding: 0;
    border-top: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
}
.hidden-xs{
    display: none;
}
.user_dropdown{
    margin-left: 0;
}
.mega-arrow{
    float: right;
    padding-top: 5px;
}
.mega-menu-hoverfull{
    position: relative;
    margin-top: 5px;
    box-shadow: none;
    background: none;
    padding-bottom: 0;
}
.main-flex-eachdiv h3{
    font-size: 15px;
    font-weight: normal;
    padding-left: 15px;
    padding: 10px 0 10px 15px;
    margin-bottom: 0;
}
.second-label-arrow{
    float: right;
    display: block;
}
.main-flex-eachdiv ul li a{
    font-size: 15px;
    font-weight: 400;
}
.main-flex-eachdiv ul li{
    padding: 5px 0px 5px 30px !important;
    border-bottom: none;
}
.megamenu-section-inner ul li a:hover {
    color: #000;
}
.sub_sub_category .dropdown-item {
    padding: 0.25rem 40px !important;
    font-size: 12px !important;
}
.sub_sub_category{
    border-bottom: none !important;
}
.product-box{
    /* for mobile version */
    min-height:325px;
}
/* .pos-rel-mobile{
    position: unset !important;
} */
.show-pin-err{
    width: 200px;
    margin-left: -70px;
    margin-top: 0;
}
.mob-location-custom {
    position: fixed;
    right: 80px;
}
.text-center-mob{
    text-align: center;
}
.w-100-mob{
    width:100%;
}
.mb-10-mob{
    margin-bottom: 10px;
}
.pr-10-mob{
    padding-right:10px !important;
}
.pl-0-mob{
    padding-left: 0;
}
.p-0-mob{
    padding: 0;
}
.pl-10-pr-10-mob{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.pin-code label{
    margin-bottom: 0;
}
.show-pin-err{
    font-size: 9px;
}
.hide-pin-err{
    display: none;
}
.checkout_mobileback{
    margin-top: 0;
}
.tagline{
    display: none;
}
.cart-info{
    display: none;
}
.cart-count{
    display: block;
}
.mobile-bord-bottom{
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}
.mob-category{
    display: block;
}
.cart-icon{
    position: static;
    right: 0px;
    top: 0px;
}
.cart-icon-blank-notification{
    position: static;
    right: 0px;
    top: 0px;
}
.product-img{
    height: 220px;
}
.prod-div{
    width: 180px;
    padding: 0 5px !important;
}

.back-icon{
    position: absolute;
    left: 0;
    top: 0;
    font-size: 15px;
    color: #929292;
    height: 46px;
    padding: 15px 13px;
    border-right: 1px solid #eae9e9;
    background: #f1f1f1;
}
.gn-style {
    position: relative;
}
.main-flex-eachdiv h3 a{
    font-size: 13px !important;
    color: #525252 !important;
}
.main-flex-eachdiv h3{
    padding: 0px 4px 0px 4px;
}
.wdicon {
    padding-left: 0;
}
.wdicon i {
    font-size: 28px;
}
.megamenu-section-inner ul li a {
    font-weight: 500;
    font-size: 14px;
    color: #5e5e5e;
}
.log-personname-mobile span img{
    width:12px;
}
.second-label-arrow img{
    width: 12px;;
}
.mr-0-mob{
    margin: 2px !important;
}
.mains-pagelogo{
    width: 100px;
}
.mobile-search-pannel {
    display: block;
    position: fixed;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 9999;
    top: 0;
}
.mob-srch-show{
    display: inline-block !important;
}
.brands-name{
    display: none;
}
.branding-div-sec4 {
    display: none;
}
.mob-pad{
    padding:7px 15px 7px 15px;
    border-bottom: 1px solid #c7c5c5;
}
.topsection-category{
    padding:0;
}
.full-Pagesection {
    padding: 55px 0 50px 0;
}
.full-Pagesection-notification-removed {
    padding: 55px 0 0px 0;
}
.full-Pagesection-announce{
    padding-top: 90px !important;
}
.products-wrapper {
    margin-top: 5px;
}
.cart-count{
    top:-2px;
    left:13px;
}
.navstylecontrol{
    padding: 0;
}
.mr-mobilecontrol{
    margin-right: 25px !important;
}
.zipname_err{
    display: none;
}
.zipname{
    display: none;
}
nav.navstylecontrol.navbar.navbar-expand-lg.border-bottom.navbar-light.bg-white.sticky-top{
    padding:0 15px;
}
.mob-martop-25px{
    margin-top: 25px;
}
.p-0-xs{
    padding:0;
}
.bord-right1px{
    border:none;
}
.categories-section .nav-item{
    flex:none;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    text-align: left;
    margin-bottom: 0px;
    text-align: left;
}
.hidd-xs{
    display: none;
}
.icon-image {
    width: 25px;
    margin-right: 10px;
}
.hc-modal {
    height: calc(100vh - 210px);
}
.categories-section .nav-link{
    padding: 7px 15px;
    min-height: auto;
    /* background: #eaeaea; */
    background: #fff;
    margin: 0;
    font-size: 17px;
    text-align: left !important;
}
.MegaDropdown-ContentHeading{
    font-size: 14px !important;
    padding: 12px 24px 12px 12px;
    border-bottom: none;
}
.branding-div-first img{
    width: 45px;
}
.branding-div-third img{
    width: 45px;
}
.branding-div.branding-div-haringhata.pl-5{
    padding:0 !important;
}
.branding-div-haringhata img {
    width: 60px;
}
.branding-div-gogras img {
    width: 60px;
}
.pr-0-mob {
    padding-right: 0;
}
.pl-0-mob {
    padding-left: 0;
}
.p-0-mob{
    padding: 0;
}
.mt-mob-0{
    margin-top: 0 !important;
}
.product-name{
    font-size: 12px;
}
.product-details-maindiv{
    height: auto;
}
.thumbs{
    margin-bottom: 0;
}
.thumbs-wrapper {
    margin: 5px 0 !important;
}
.product-description-mainname{
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.product-price-product-details {
    font-size: 16px;
    margin: 15px 0 !important;
}
.details-description-section p {
    margin-top: 10px !important;
    font-size: 14px;
}
.itemlist {
    margin-top: 5px;
}
.lh-mob-20{
    line-height: 20px;
}
.total-amnt-lbl {
    width: 100%;
    margin-bottom: 5px;
    font-size: 20px;
}
.product-links-anchor{
    min-width:130px;
}
.chk-out-button button{
    min-width: 130px;
}
.chk-out-button{
    padding: 6px 15px;
}
.pos-fixedclass{
    z-index: 2;
    bottom:61px;
}
.bot-autofd-div{
    bottom: 0;
    z-index: 9;
    height: 40px;
    margin-top: 37px;
}
.checkout_edit_mobile{
    float: right;
}
.cart-items{
    margin-bottom: 85px;
}
.itemlistinner {
    margin-bottom: 10px;
    box-shadow: inset 0 0 4px #f3eaea;
}
.vl-top-d-inline-mob{
    display: inline-block;
    vertical-align: top;
}
.mob-wid-30{
    width:30%;
}
.mob-wid-65{
    width:65%;
    padding: 0 10px;
}
.mt-10-mob{
    margin-top: 10px;
}
.centered-div .addtocart-btn{
    padding: 0 5px;
    font-size: 11px;
}
.decrement{
    width:23px;
    padding: 2px 9px;
    /* height: 28px; */
}
.decrement img{
    left:-3px;
}
.increment{
    width:23px;
    padding: 2px 7px;
    /* height: 28px; */
}
.quantity{
    width: 45px;
    /* height: 28px; */
    font-size: 14px;
}
.mob-hide{
    display:none !important;
}
.quick-view-image{
    background: #fff !important;
    width:100%;
    margin:0 auto;
    height: 420px;
}
.quick-view-image img{
    height: 100%;
    object-fit: contain;
}
.carousel .slide{
    background: transparent !important;
    min-height: 160px;;
}
.small-banner .carousel .slide {
    min-height: 85px;
}
.small-banner .carousel .slide img{
    object-fit: cover;
    min-height: 85px;
}
.control-dots .dot {
    box-shadow: 0 0 3px #000000 !important;
    background: #000000 !important;
    width: 8px !important;
    height: 8px !important;
    margin: 0 5px !important;
}
.carousel .control-prev.control-arrow:before{
    border-right:8px solid #000 !important;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
}
.carousel .thumb img {
    vertical-align: top;
    width:100% !important;
}

.mob-font-11px{
    font-size: 11px;
}
.map-icon{
    font-size: 20px;;
}
.no-mobfont{
    font-size: 10px;
}
.alt_mobile_no{
    margin-left:19px;
}
.product_loading{
    width: 100%;
    height:129px;
}

.bttn{
    height: 31px;
    border-radius: 21px;
    margin-left: 20px;
    margin-bottom: 5px;
}
.table-blank-p {
    min-width: 145px;
}
.order_accordion{
    width:100%;
}
.order_ack_button{
    width:100%;
    margin-top: 10px;
}
.order_inv_button{
    width:100%;
    margin-top: 10px;
}
.chkout-top{
    flex-direction: column;
}
.Banner-crousal .carousel .slide img{
    height: 200px !important;
    object-fit: cover;
}
.p-1-mob{
    padding: 0 4px 0 4px !important;
}
.item-on-div {
    margin-bottom: 15px;
    border: 1px solid #ebebeb;
    transition: transform .2s;;
}
.mb-0-mob{
    margin-bottom: 0 !important;
}
.footer-last-bottom {
    padding: 45px 0;
    padding-top: 10px;
    text-align: center;
}
.footer-small-menu li a{
    font-size: 11px;
}
.footer-small-menu{
    float: none;
}
.footer-small-menu li{
    float: none;
    display: inline-block;
}
.p-0-mob{
    padding:0 !important;
}
.f-social-logo {
    margin: 0px 0 15px 0;
}
.widget-title-footer{
    margin: 30px 0 10px;
}
.search-formques{
    width: 100%;
}
.a-text-regular{
    width: calc(100% - 80px);
}
.product-name-cart-newpage {
    font-size: 13px!important;
    line-height: 18px!important;
    display: block;
}
.product-price-newpage{
    text-align: left;
}
.prod-variant-text {
    margin: 3px 0 0;
}
.live-loc-div button{
    font-size: 13px;
}

.add-new-add-link{
    margin-left: 0;
    margin-top: 6px;
}
.to-continue-button{
    position: fixed !important;
    bottom: 0px;
    background-color: #ffffff;
    padding: 8px 15px;
    z-index: 99;
    margin: 0 !important;
    border-top: 1px solid #dfd4d4;
    left: 0;
}
.to-continue-button .primary-button{
    height: 35px;
}
.right-sm-ul{
    display: none;
}
.header-top-leftcontact-section {
    display: block;
}
.header-top-rightcontact-section {
    display: block;
}
.mob-d-block{
    display: block !important;
}

.header-top{
    padding: 3px 0px 3px 0px;
    background-color: #f2f2f2;
    display: none;
}
.mob-logo{
    height: 45px;
}
.header-bottom-rightcontact-section{
    text-align: right;
}
.header-top-leftcontact-section a {
    font-size: 12px;
    margin-right: 8px;
    width: 100%;
    display: block;
}
.sh-hd-search{
    display: none;
}
.mobile-search-div{
    margin-right: 0px;
}
.nonactvuser{
    padding-left: 0px;
}
.mobile-search-div .search-icon {
    left: 0;
    top: 16px;
    width: 20px;
}
.searchbackicon{
    width: 20px;
    padding-top: 5px;
}
.mobile-bar{
    color: #525252;
    font-size: 23px;
    top: 2px;
    position: relative;
}
.pr-0-mob{
    padding-right: 0 !important;
}
.mobile-seach-div{
    padding: 5px 0 !important;
    display: none;
}

.pt-5px-mob{
    padding-top: 5px !important;
}
.pb-5px-mob{
    padding-bottom: 5px !important;
}
.fix-div-mobilecategory{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(98 96 96 / 100%);
    z-index: 99;
}
.mobile-category-menupannel {
    width: calc(100% - 50px);
    height: 100%;
    background: #fff;
    padding-top: 0px;
    max-width: 400px;
    position: relative;
}
.old-typemenu{
    position: initial;
}
.sub_category {
    padding: 3px 10px;
}
.mobile-close-icon{
    position: absolute;
    right: 15px;
    top: 0;
    font-size: 30px;
    color: #fff;
}
.fix-div-mobilecategory{
    display: block;
}
.copyright-small {
    margin-top: 5px;
}
.product-image-cart{
    width:100%
}
.page-lab-head{
    padding:10px 0px 10px 0px;
}
.pl-5px-mob{
    padding-left: 5px !important;
}
.full-page-body{
    padding-top: 42px !important;
}
.full-page-body-announce{
    padding-top: 90px !important;
   }
.slick-prev:before{
    content:url('../img/prv-ar-drk-old.png') !important; 
    border:none !important;
    opacity: 1 !important;
}
.slick-next:before{
    content:url('../img/next-arr-drk-old.png') !important; 
    border:none !important;
    opacity: 1 !important;
}
.slick-prev, .slick-next {
    width: 27px !important;
    height: 27px !important;
    background: #ffffff !important;
    transition: 0.3s;
    border-radius: 50%;
    box-shadow: 0 0 5px #a1a1a1;
}
.slick-prev:before, .slick-next:before {
    top:2px;
    left: 7px !important;
    line-height: 17px !important;
}
.slick-prev:hover, .slick-next:hover {
    background: #fff !important;
}
.slick-prev:before{
    left: 4px !important;
}
.contact-margin-mob{
    margin-bottom: 25px;
}
.contact-info-widget{
    padding-left: 0 !important;
}
.prod {
    height: 160px !important;
}
.prod-img{
    height: 160px !important;
}
.mob-profilepannel{
    /* background-color:var(--primaryColor) !important; */
    padding: 10px 15px;
    background-color:var(--primaryColor,0.5) !important ;
    position: relative;
}
/* .mob-profilepannel:before{
    position: absolute;
    content: " ";
    width:100%;
    height: 100%;
    top:0;
    left:0;   
} */
.mob-proficon {
    display: inline-block;
    color: #000;
    font-size: 20px;
    border: 1px solid #fff;
    padding: 8px 15px;
    background: #fff;
    border-radius: 5px;
}
.log-personname-mobile{
    color:#fff;
    text-transform: capitalize;
    padding: 8px 0 0 0px;
    position: relative;
}
.log-personname-mobile span{
    float: right;
}
.m-cont-l-r {
    padding: 8px 8px;
}
.mobile-style-fixed-button{
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 99;
    bottom: 0px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 15%) 0px -1px 3px 0px;
    padding: 0px 0px;
    text-align: right;
    display: flex;
    margin-bottom: 0;
}
.mobile-style-fixed-button button{
    margin-bottom: 0 !important;
    min-width:50%;
    margin: 0 !important;
    border-radius: 0;
    height: 42px;
    border:none;
}
.mobile-style-fixed-button .primary-button{
float: right;
}
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .megamenu-section{
        padding: 4px 0;
    }
    .copyright-small small{
        font-size: 14px;
    }
    .rest-srchdiv{
        padding-left: 0 !important;
    }
    .mt-5-mobile{
        margin-top: 0 !important;
    }
    .item-subtotal-right {
        padding: 30px 20px;
    }
    .main-add-main {
        flex: 0 0 50%;
        max-width: 50%;
        word-break: break-all;
    }
    .main-flex-eachdiv ul li a{
        font-size: 12px;
        padding: 0 !important;
    }
    .mega-menu-hoverfull{
        margin-top: 90px;
    }
    .d-inline-block.align-top.text-center.comm-hovclass.comm-marclass.m-cont-l-r {
        padding: 7px 0;
    }
    .topsection-header {
        padding: 5px 0px 5px 0px;
    }
    .main-megamenu {
        font-size: 13px !important;
        padding: 0 5px !important;
    }
    .megamenu-section-inner{
        padding-left: 0;
    }
    .mega-arrow-svg {
        width: 10px;
        margin-left: 3px;
    }
    .p-0-tab{
        padding: 0 !important;
    }
    .full-page-body {
        padding-top: 95px;
    }
    .megamenu-section-inner ul li a{
        padding: 18px 20px 16px 10px;
    }
    .pl-0-tab{
        padding-left: 0 !important;
    }
    .pr-0-tab{
        padding-right: 0 !important;
    }
    .brand .logo {
        width: 90px;
        height: auto;
    }
    .pin-code label{
        margin-bottom: 0;
    }
    .pin-code img{
        width: 20px;
    }
    .show-pin-err{
        font-size: 11px;
        width: 245px;
        margin-left: -100PX;
        margin-top: 5px;
    }
    .tagline {
        font-size: 11px;
        margin-top: 4px;
    }
    .cart-info{
        font-size: 14px;
    }
    .search-form .search-keyword{
        width: 130px !important;
    }
    .sub_category .dropdown-menu{
        width: 250px;
    }
    .prod-div{
        width:240px;
    }
    .pos-sta-tab{
        position: static !important;
    }
    .amount-setdiv {
        top: 17px;
        left: 0;
    }
    .tab-width-cont{
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }
    .categories-section .nav-link{
        margin: 0 2px;
        padding: 7px 7px;
        /* font-size: 13px; */
    }
    .rest-srchdiv {
        padding: 0px 0px 0px 0;
    }
    .topsection-category{
        padding: 5px 0;
    }
    .full-Pagesection {
        padding: 80px 0 50px 0;
    }
    
    .branding-div-first img {
        width: 45px;
    }
    .branding-div-third img{
        width: 45px;
    }
    .brands-name{
        margin:12px 0;
        font-size: 14px;
    }
    .addressbrands-second{
        font-size: 12px !important;
    }
    .p-0-5-tab{
        padding: 0 5px;
    }
    .centered-div .addtocart-btn{
        padding: 0 5px;
        font-size: 11px;
    }
    .decrement{
        width:20px;
        padding: 2px 6px;
    }
    .decrement img{
        left:0px;
    }
    .increment{
        width:20px;
        padding: 2px 5px;
    }
    .quantity{
        width: 35px;
    }
    .tab-hide{
        display:none;
    }
    .table-blank-p {
        min-width: 100px;
    }
    .right-sm-ul li a{
        padding: 0 7px;
    }
    .mains-pagelogo {
        width: 100%;
    }
   
}
@media screen and (min-device-width: 1023px) and (max-device-width: 1280px) {
    .categories-section .nav-link{
        padding: 10px 7px !important;
    }
    .categories-section {
        padding: 0px 20px 0px 5px;
        width:auto;
    }
    .search-form .search-keyword {
        width: 200px !important;
    }
}
@media (min-width: 1360px){
    .container{
        max-width: 1280px !important;
    }
}
@media (min-width: 1600px){
    .container{
        max-width: 1350px !important;
    }
    .Banner-crousal .carousel .slide img {
        height: 440px !important;
    }
    .for-indiperstyle-menu {
        width: 700px;
    }
}
@media (min-width: 1680px){
    .container{
        max-width: 1400px !important;
    }
    .Banner-crousal .carousel .slide img {
        height: 460px !important;
    }
    .for-indiperstyle-menu {
        width: 740px;
    }
}
@media (min-width: 1900px){
    .container{
        max-width: 1650px !important;
    }
    .Banner-crousal .carousel .slide img {
        height: 560px !important;
    }
    .for-indiperstyle-menu {
        width: 915px;
    }
    
}
/*----------------------------------------- Responsive css End --------------------------------------*/

/*----------------------------------------- Responsive css End --------------------------------------*/



/* -------------------------------------------new-08.02.2023-------------------- */

.garment-header{
    text-transform: capitalize;
    font-size: 15px;
    color: #707070;
}
.garment-type-inner{
    position: relative;
    overflow: hidden;
    height: 100%;
    transition: 0.5s;
    cursor: pointer;
}
.garment-type-inner:hover{
    transition: 0.5s;
}
.abs-img:hover{
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    transition: 0.5s;
}
.small-banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.small-banner-text h1{
    color: #fff;
    margin-bottom: 0;
}
.small-banner-text p{
    color: #fff;
    font-style: italic;
}
.medium-banner-text{
    color: #fff;
    margin-bottom: 0;
}
.medium-banner-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.medium-banner-text h1{
    color: #fff;
    margin-bottom: 0;
}
.left-categorymenu{
    background-color: #f3f3f3;
    padding: 0 15px;
    height:400px;
    overflow-x: hidden;
    overflow-y: auto;
}
.left-categorymenu ul li{
    display: block;
    width: 100%;
    padding: 12px 0px;
    border-bottom: 1px solid #dadada;
}
.left-categorymenu ul li a{
    padding-left: 0 !important;
}
.newmegsvg {
    float: right;
    transform: rotate(265deg);
    margin-top: 5px;
}
.active-submenu{
    color: var(--primaryColor) !important;
}
.active-submenu .newmegsvg line{
    stroke: var(--primaryColor) !important;
}
.each-item-ne-menu li{
    width:32%;
    display: inline-block;
    vertical-align: top;
}
.each-item-ne-menu li a {
    padding: 4px 0 !important;
    display: block !important;
    width: 100%;
    font-weight: normal !important;
    font-size: 14px !important;
}